<template>
  <div class="divider" :class="{ large }"></div>
</template>

<script>
export default {
  name: "Divider",
  props: {
    large: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.divider {
  width: 0;
  height: 0;
}

.large {
  border-top: 200px solid transparent;
  border-left: 50vw solid #577bc91f;
  border-right: 50vw solid #577bc91f;
}
</style>
