<template>
  <b-container fluid class="footer-container">
    <b-container>
      <div class="d-flex flex-wrap justify-content-around">
        <div>
          <div>
            <b-img
              :src="require('@/assets/images/logo.png')"
              width="200"
              fluid
              alt="Potomac Dining Logo"
              class="image"
            >
            </b-img>
          </div>
        </div>
        <div class="d-none d-lg-block">
          <h2 class="footer-header pb-3">Company</h2>
          <div class="links" v-for="link in links" :key="link.name">
            <b-link :to="{ name: link.name }"> {{ link.display }}</b-link>
          </div>
        </div>

        <div class="d-block d-lg-none">
          <h2 class="footer-header pb-3 center">Company</h2>
          <div class="links" v-for="link in links" :key="link.name">
            <div class="d-flex justify-content-center">
              <b-link :to="{ name: link.name }"> {{ link.display }}</b-link>
            </div>
          </div>
        </div>

        <div class="contact d-none d-lg-block">
          <h2 class="footer-header pb-3">Contact</h2>
          <div class="pb-3">13873 Park Center Road Herndon, Virginia 20171</div>
          <div>Tel: 240-752-7565</div>
          <div class="pb-3">Fax: 703-787-6550</div>
          <b-link class="email-link">info@potomacdining.com</b-link>
          <SocialMediaIcons class="pt-4" />
        </div>

        <div class="contact center d-block d-lg-none">
          <div class="d-flex justify-content-center">
            <h2 class="footer-header pb-3">Contact</h2>
          </div>
          <div class="pb-3">13873 Park Center Road Herndon, Virginia 20171</div>
          <div>Tel: 240-752-7565</div>
          <div class="pb-3">Fax: 703-787-6550</div>
          <b-link class="email-link">info@potomacdining.com</b-link>
          <SocialMediaIcons class="pt-4" />
        </div>
      </div>
      <div></div>
      <div class="copyright">
        © Copyright {{ new Date().getFullYear() }} - Potomac Family Dining Group
        - All Rights Reserved
      </div>
    </b-container>
  </b-container>
</template>

<script>
import SocialMediaIcons from "@/components/shared/SocialMediaIcons";

export default {
  components: { SocialMediaIcons },

  data() {
    return {
      links: [
        {
          name: "Contact",
          display: "Contact",
        },
        {
          name: "Locations",
          display: "Locations",
        },
        {
          name: "Careers",
          display: "Careers",
        },
        {
          name: "Employee",
          display: "Employee Login",
        },
      ],
    };
  },
};
</script>

<style>
.footer-container {
  border-top: 1px solid #ffa500 !important;
  background-color: #fffdfa;
  padding-bottom: 20px;
  padding-top: 20px;
  max-width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.image {
  padding-top: 30px;
}

.copyright {
  border-top: 1px solid #ffa500;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.footer-header {
  font-size: 20px;
  color: #000080;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: left;
  padding-top: 30px;
  font-weight: 600;
}

.contact {
  text-align: left;
  padding-bottom: 50px;
}

.center {
  text-align: center;
}

.links {
  text-align: left;
  padding-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-size: 16px;
}
</style>
