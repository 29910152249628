<template>
  <div class="event-container">
    <Loading v-if="loading || fundraisersLoading"/>
    <div style="text-align:left" v-else>
      <PageTitle :subtitle="event.orgInfo.name"/>
      <div v-if="event.approved">
        <div>
          Your event has been approved by Potomac Family Dining. <br> Enter the ticket price below to generate your
          Tickets
          and Flyers for download.
        </div>
        <div class="mt-5">
          <div v-if="fundraiser.name === 'Dining To Donate'">
            <DineToDonateEventFlyers :event="event" :restaurant="restaurant"/>
          </div>
          <div v-else-if="fundraiser.name === 'Flapjack Fundraiser'">
            <div v-if="event.ticketPrice">
              Download your flyer and tickets below
              <FlapJackFundraiser
                  v-if="fundraiser.name === 'Flapjack Fundraiser' "
                  :event="event"
                  :restaurant="restaurant"
                  class="my-3"
              />
              <FlapjackTickets
                  v-if="fundraiser.name === 'Flapjack Fundraiser'"
                  :event="event"
                  :restaurant="restaurant"
                  class="my-3"
              />
            </div>
            <div class="mb-3" v-else>
              Set the ticket price to access flyers and tickets. (Required)
              <div v-if="event.ticketPrice">
                <div class="title">Ticket Price</div>
                <div class="mb-2">
                  ${{ event.ticketPrice }}
                </div>
              </div>
            </div>
            <SetTicketPrice :event="event" @setPrice="() => {this.getEvents()}"/>
          </div>
          <div v-else>
            Together flyer
            <TogetherWeCare :event="event" :restaurant="restaurant"/>
          </div>
        </div>
      </div>
      <div v-else>
        Event has not yet been approved, please contact <a href="mailto:info@potomacdining.com">Potomac Dining</a> for
        details.
      </div>
      <div class="my-4">
        <div v-if="fundraiser.name === 'Flapjack Fundraiser'">
          <div class="title">Important Files</div>
          <div>
            <a
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/potomac-family-dining.appspot.com/o/eventFiles%2FVolunteer%20Waiver%20Release%20-%20Flapjack%20Fundraisers.pdf?alt=media&token=1a1536e0-0922-4a2c-ab1e-c2d006c49e63"
            >
              Volunteer Waiver and Release of Liability
            </a>
          </div>
          <div>
            <a
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/potomac-family-dining.appspot.com/o/eventFiles%2FCharity%20Guide%20-%20Flapjack%20Fundraisers.pdf?alt=media&token=69709c4e-1cae-4587-a396-c41e0ca46c21"
            >
              FlapJack Fundraiser Charity Guide
            </a>
          </div>
        </div>
        <div v-else-if="fundraiser.name === 'Dining To Donate'">
          <div class="title">Important Files</div>
          <div>
            <a
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/potomac-family-dining.appspot.com/o/eventFiles%2FDining%20to%20Donate%20Agreement.pdf?alt=media&token=2e4414c0-0d71-42de-ad78-79f2a85d4517"
            >
              Dining To Donate Agreement
            </a>
          </div>
          <div>
            <a
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/potomac-family-dining.appspot.com/o/eventFiles%2FDining%20to%20Donate%20Guest%20Information.pdf?alt=media&token=d0332b65-2875-4dc6-885d-7283da8d9a2b"
            >
              Dining To Donate
            </a>
          </div>
        </div>
        <div v-else>
<!--          Nothing to show for Together we Care-->
        </div>
      </div>
      <div>
        <div class="mb-2 mt-4" v-if="userOwned || admin || restaurantOperator">
          <div class="title right">
            <span v-if="fundraiser.orgInfo.w9Required">required</span>
          </div>
          <div
              v-if="url !== '' && !editWNine"
          >

            <div>
              <div>W9 on file</div>
              <b-btn @click="editWNine = true" size="sm" class="mr-3 mt-2 mb-4" variant="success">
                Add new W9
              </b-btn>
              <b-btn
                  size="sm"
                  class="mr-3 potomac-btn"
                  variant="success"
                  v-if="admin"
                  to="#"
                  @click.prevent="download"
              >
                Download
              </b-btn>
            </div>
          </div>
          <div v-else>
            <div class="title mb-2">W9 Upload</div>
            <b-form-file
                v-model="wNine"
                :state="Boolean(wNine)"
                placeholder="Upload w9 here"
                drop-placeholder="Drop w9 here..."
                class="my-2"
            />
            <div v-if="uploadError">{{ uploadError }}</div>
            <b-btn @click="upload" size="sm" variant="success">Upload</b-btn>

          </div>
        </div>
      </div>

      <b-button class="potomac-btn my-4" v-b-toggle="'collapse-2'">View Event Details</b-button>
      <b-collapse id="collapse-2">
        <EditEvent v-if="admin && editing" @closeEdit="editing = !editing" :event="event"/>
        <div v-else>
          <b-btn
            variant="link"
            v-if="admin"
            @click="editing = !editing"
          >
            <span>Edit</span>
          </b-btn>
          <div class="title">Fundraiser Type</div>
          <div class="mb-2">{{ fundraiser.name }}</div>
          <EventDate :event="event"/>
          <EventOrg :event="event"/>
          <div>
            <div class="title">Email</div>
            <div class="mb-2">{{ event.orgInfo.email }}</div>
          </div>
          <EventOrgAddress :event="event"/>
          <EventOrgContact :event="event"/>
          <EventOrgEmail :event="event"/>
          <EventOrgPhoneNumber :event="event"/>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import PageTitle from "@/components/shared/PageTitle";
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage';
import {doc, getFirestore, updateDoc} from 'firebase/firestore';
import Admin from '@/components/shared/mixins/admin';
import FlapJackFundraiser from "@/components/Flyers/FlapJackFundraiser";
import FlapjackTickets from "@/components/Flyers/FlapjackTickets";
import SetTicketPrice from "@/components/SetTicketPrice";
import DineToDonateEventFlyers from "@/views/DineToDonateEventFlyers";
import Loading from "@/components/shared/Loading";
import EventDate from "@/views/EventDate";
import EventOrg from "@/views/EventOrg";
import EventOrgAddress from "@/views/EventOrgAddress";
import EventOrgContact from "@/views/EventOrgContact";
import EventOrgEmail from "@/views/EventOrgEmail";
import EventOrgPhoneNumber from "@/views/EventOrgPhoneNumber";
import EditEvent from "@/components/EditEvent";
import TogetherWeCare from "@/components/Flyers/TogetherWeCare";

export default {
  name: "Event",
  components: {
    TogetherWeCare,
    EditEvent,
    EventOrgPhoneNumber,
    EventOrgEmail,
    EventOrgContact,
    EventOrgAddress,
    EventOrg,
    EventDate,
    Loading, DineToDonateEventFlyers, SetTicketPrice, FlapjackTickets, FlapJackFundraiser, PageTitle},
  props: ['id'],
  mixins: [Admin],
  computed: {
    ...mapState('events', ['loading']),
    ...mapState('fundraisers', {byId: 'byId', fundraisersLoading: 'fundraisersLoading'}),
    ...mapState('user', ['user']),
    ...mapState('restaurants', ['restsObj']),
    fundraiser() {
      if (!this.loading) {
        return this.byId[this.event.fundraiser]
      }
    },
    event() {
      return this.$store.getters['events/getById'](this.id)
    },
    userOwned() {
      if (this.user && this.user.currentUser) {
        return this.event.user === this.user.currentUser.uid
      }
      return false;
    },
    restaurantOperator(){
      return this.user?.userData?.restaurant === this.event.restaurant
    },
    restaurant() {
      return this.restsObj[this.event.restaurant]
    }
  },
  methods: {
    ...mapActions('events', ['getEvents']),
    upload() {
      if (this.wNine) {
        if (this.userOwned || this.admin) {
          const storage = getStorage();
          const wNineRef = ref(storage, `wnines/${this.id}`);
          this.uploadError = null;
          uploadBytes(wNineRef, this.wNine).then((snapshot) => {
            console.log(snapshot.metadata);
            updateDoc(doc(getFirestore(), `events/${this.id}`), {
              wNine: snapshot.metadata.fullPath
            })
          }).catch(e => {
            this.uploadError = "File too large"
          })

        } else {
          this.uploadError = "Not Authorized"
        }
      } else {
        this.uploadError = "Please add a file"
      }
    },

    download({url, label}) {
        const link = document.createElement('a')
        link.href = this.url;
        link.download = label;
        link.click()
        this.event.wnine = `/wnines/${this.event.id}`
        // revoke download url
    },
    getWNineURL() {
      getDownloadURL(ref(getStorage(), `wnines/${this.id}`))
          .then(url => {
            console.log(url)
            this.url = url;
          })
          .catch((e) => {
            // drop error we don't care about it here
          })
    }
  },
  mounted() {
    if (this.loading) {
      this.getEvents();
    }
    this.getWNineURL();
  },
  data() {
    return {
      wNine: null,
      uploadError: false,
      editWNine: false,
      downloadURL: false,
      ticketPrice: 0,
      editing: false,
      url: ''
    }
  }
}
</script>

<style scoped>
.title {
  color: #000080;
}

.event-container {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
}

.event-container {
  max-width: 600px;
}

.title {
  color: #000080;
}

</style>
