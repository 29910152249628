<template>
  <b-container class="d-flex flex-wrap justify-content-around">
    <div class="section">
      <div class="pb-4">
        <b-icon-house-door-fill
          class="icon icon-border"
        ></b-icon-house-door-fill>
      </div>
      <div class="pb-3 d-flex justify-content-center">
        <h2 class="header">Neighbors</h2>
      </div>
      <div>
        We are Applebee's Neighborhood Grill and Bar. Neighbor is in our name.
        We are a proud contributor to every neighborhood and community we are a
        part of.
      </div>
    </div>
    <div class="section mt-5 mt-md-0">
      <div class="pb-4">
        <b-icon-people-fill class="icon icon-border"></b-icon-people-fill>
      </div>
      <div class="pb-3 d-flex justify-content-center">
        <h2 class="header">Teams</h2>
      </div>
      <div>Better Together means One for All and All for One.</div>
    </div>
    <div class="section mt-5 mt-lg-0">
      <div class="pb-4">
        <b-icon-bar-chart-fill class="icon icon-border"></b-icon-bar-chart-fill>
      </div>
      <div class="pb-3 d-flex justify-content-center">
        <h2 class="header">Leaders</h2>
      </div>
      <div>
        Leaders Lead, and Potomac Family Dining Group prioritizes the
        development of Leaders.
      </div>
    </div>
  </b-container>
</template>
<script>
export default {
  name: "IconSection",
};
</script>
<style scoped>
.section {
  min-width: 250px;
  max-width: 300px;
}

.icon {
  background-color: #fffdfa;
  color: #ffa500;
  font-size: 50px;
}

.icon-border {
  border: 1px solid #ffa500;
  border-radius: 50%;
  padding: 20px;
  height: 75px;
  width: 75px;
}

.header {
  color: #000080;
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 2.5px;
  font-weight: 500;
}
</style>
