<template>
  <div class="agreement">
    <p>
      Applebee's will donate 20% of any check on your specific day (excluding tax, alcohol sales and gift card
      sales)
      that is accompanied by a valid Dining to Donate flyer for your group/organization.
    </p>
    <p>
      After your Fundraiser date request is confirmed by an Applebee’s Manager via e-mail, your W-9 will be checked
      for any deficiencies. Once your event date and W-9 have been confirmed you will receive an email with your
      username
      and password allowing you to access your flyers at <a href="https://potomacdiningserves.com/">potomacdiningserves.com</a>.
      Please make sure all
      necessary
      fields are filled out on your W-9 and agreement, otherwise your date will need to be released back to the
      public
      and your event will have to be rescheduled.
    </p>
    <p>
      Please bring your signed Dining to Donate contract on the day of your event and an Applebee's Manager will
      keep
      it on file for you.
    </p>
    <p>
      Guests may dine in or take out food (not valid in all locations) for the fundraiser. As long as they have a
      flyer with them, their donation will be counted!
    </p>
    <p>
      Dining to Donates are hosted Sunday through Thursday only. (Days may vary per location).
    </p>
    <p>
      Only a valid Dining to Donate flyer will be counted for donation.
    </p>
    <p>
      Flyers must be distributed prior to the event night. You may NOT stand outside the restaurant and hand out
      flyers to customers entering the restaurant. If your organization distributes flyers during your event, no
      funds
      will
      be donated and your event will be canceled. The restaurant will not supply additional flyers for your Dining
      to
      Donate on the day your event is hosted.
    </p>
  </div>
</template>
<script>
export default {
  name: 'DinetoDonateAgreement'
}
</script>
<style scoped>

.agreement {
  text-align: left;
  max-width: 600px;
  margin: auto;
}
</style>
