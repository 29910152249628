<template>
  <div class="d-flex flex-wrap justify-content-around my-5 px-5 career-section">
    <b-img
        v-if="left"
        class="align-self-center section-image mr-lg-4 mb-5 mb-xl-0"
        :src="`/images/${image}`"
        :alt="alt"
    ></b-img>

    <b-img
        v-else
        class="align-self-center section-image mr-lg-4 mb-5 mb-xl-0 d-block d-xl-none"
        :src="`/images/${image}`"
        :alt="alt"
    ></b-img>

    <div class="text-section mx-3">
      <h3 class="section-title">{{ title }}</h3>
      <div class="pb-5">
        <div
            v-for="item in items"
            :key="item"
            class="section-item d-flex align-items-center mb-3 mb-md-0"
        >
          <b-icon-caret-right class="icon mr-3"></b-icon-caret-right>
          {{ item }}
        </div>
      </div>

      <div>
        <b-button class="potomac-btn" v-b-toggle="id">Learn More</b-button>
      </div>
    </div>
    <b-img
        v-if="!left"
        class="section-image d-none d-xl-block"
        :src="`/images/${image}`"
        :alt="alt"
    ></b-img>
    <b-collapse :id="id">
      <b-container v-if="description.length > 0" class="pt-2 pb-5 mt-4">
        {{ description }}
      </b-container>

      <h3 v-if="requirements.length > 0" class="section-title">
        Requirements:
      </h3>

      <div v-for="requirement in requirements" :key="requirement">
        <div class="d-flex align-items-center">
          <b-icon-check class="icon mr-3"></b-icon-check>
          {{ requirement }}
        </div>
      </div>

      <h3 class="pt-4 pb-3">Benefits:</h3>

      <div
          v-for="benefit in benefits"
          :key="benefit"
          class="d-flex align-items-center"
      >
        <b-icon-check class="icon mr-3"></b-icon-check>
        {{ benefit }}
      </div>
      <ApplyButton class="mt-5 mb-3 d-flex justify-content-center"/>
    </b-collapse>
  </div>
</template>

<script>
import ApplyButton from "../../components/shared/ApplyButton.vue";

export default {
  components: {ApplyButton},

  name: "Section",

  props: {
    left: {
      type: Boolean,
    },
    id: {},
    image: {},
    alt: {
      default: "",
    },
    title: {
      default: "Title",
    },
    items: {
      default: ["item 1", "item 2", "item 3"],
    },
    description: {
      default: "Description",
    },
    requirements: {
      default: ["item 1", "item 2", "item 3"],
    },
    benefits: {
      default: ["item 1", "item 2", "item 3"],
    },
  },
};
</script>

<style scoped>
.section-image {
  max-width: 80vw;
  height: auto;
  width: 500px;
  box-shadow: 5px 5px 10px 5px rgba(54, 49, 49, 0.13);
}

.section-text {
  text-align: left;
}

.section-title {
  margin-bottom: 30px;
  color: #000000;
  font-size: 26px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.12em;
  border-bottom: 5px solid #ffa500;
  padding-bottom: 15px;
}

.career-section {
  padding-bottom: 30px;
  text-align: left;
}

.section-item {
  padding-bottom: 10px;
}

.icon {
  color: #ffa500;
  font-size: 25px;
}
</style>
