<template>
  <div>
    <PageTitle title="Employee Page"/>
    <EmployeeUploader @updated="getFiles"/>
    <b-container>
      <b-tabs>
        <b-tab
            v-for="category in categories"
            :key="category.id"
            :title="category.name"
        >
          <TrainingFile
              v-for="file in category.files"
              :key="file.fullPath"
              :file="file"
              @update="getFiles"
          />
          <div
              v-if="category.files.length === 0"
              class="my-5"
          >
            No files in this category
          </div>

        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle";
import EmployeeUploader from "@/components/EmployeeUploader";
import {mapActions, mapMutations, mapState} from 'vuex';

import {getMetadata, getStorage, listAll, ref} from "firebase/storage";
import TrainingFile from "@/components/TrainingFile";
import admin from "@/components/shared/mixins/admin";

export default {
  components: {TrainingFile, EmployeeUploader, PageTitle},
  mixins: [admin],

  data() {
    return {
      trainingFiles: [],
    }
  },

  methods: {
    ...mapActions('categories', ['getCategories']),
    ...mapMutations('categories', ['addFile']),
    getFiles() {
      let storage = getStorage();
      const training = ref(storage, 'employeeDocs/');
      this.trainingFiles = [];
      listAll(training)
          .then(res => {
            res.items.forEach((itemRef, ind) => {
              this.trainingFiles.push(itemRef);
              getMetadata(ref(storage, itemRef.fullPath))
                  .then(metadata => {
                    if (metadata.customMetadata?.category) {
                      this.addFile({
                        index: this.categories.findIndex(c => {
                          return c.id === metadata.customMetadata?.category
                        }),
                        file: itemRef
                      })

                    } else if (!metadata.customMetadata?.category){
                      this.addFile({
                        index: this.categories.findIndex(c => c.name === 'Other'),
                        file: itemRef
                      })
                    }
                  })
            })
          })
    },
  },

  computed: {
    ...mapState('categories', ['categories', "filesAdded"]),
  },

  mounted() {
    this.getCategories();
    this.getFiles();
  },

  watch: {
    filesAdded(){
      this.$nextTick(() => {
        if (!this.filesAdded){
          this.getFiles();
        }
      })
    }
  }

};
</script>

<style></style>
