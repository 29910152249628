<template>
  <div>
    Download your flyer below

    <DineToDonate
        :event="event"
        :restaurant="restaurant"
        class="my-3"
    />
  </div>
</template>
<script>
import DineToDonate from "@/components/Flyers/DineToDonate"

export default {
  name: 'DineToDonateEventFlyers',
  components: {DineToDonate},
  props: {
    event: {},
    restaurant: {}
  }
}
</script>
