<template>
<div>
  <b-form-select
      v-model="selected"
      :options="options"
      @change="categoryChange"
  />
  <b-modal
      :ref="`new-category-${file.fullPath}`"
      @ok="okHandler"
      :no-enforce-focus="true"
  >
      <b-form-input
        v-model="newCategoryName"
        placeholder="New Category Name"
      />
  </b-modal>
</div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import { getStorage, ref, updateMetadata, getMetadata} from "firebase/storage";
export default {
  name: "CategorySelector",
  props: ['file'],
  data(){
    return {
      selected: '',
      newCategoryName: ''
    }
  },
  computed: {
    ...mapState('categories', ['categories']),
    options(){
      return this.categories
          .map(cat => {
            return {
              value: cat.id,
              text: cat.name
            }
          })
          .concat({
        value: 'new',
        text: 'Add new category'
      })
    }
  },
  methods: {
    ...mapActions('categories', ['newCategory', 'getCategories']),
    categoryChange(){
      if (!this.selected){return};
      if (this.selected === 'new'){
        this.$refs[`new-category-${this.file.fullPath}`].show();
      } else {
        updateMetadata(ref(getStorage(), this.file.fullPath), {
          customMetadata: {
            category: this.selected
          }
        }).then(() => {
          this.$notify({
            group: 'main-notifications',
            title: 'Category Added',
            text: 'Category Added to file',
            type: 'success',
          })
          this.getCategories();

        }).catch(() => {
          this.$notify({
            group: 'main-notifications',
            title: 'Error',
            text: 'Error adding category',
            type: 'error'
          })
        })
      }
    },

    setSelected(){
      const storage = getStorage();
      const fileRef = ref(storage, this.file.fullPath);
      getMetadata(fileRef)
          .then(metadata => {
            this.selected = metadata.customMetadata?.category
          })
    },

    okHandler(bvModalEvent){
      this.newCategory(this.newCategoryName);
    },
  },
  watch: {
    categories(){
      this.setSelected();
    }
  },
  mounted(){
    this.setSelected();
  }
}
</script>

<style scoped>

</style>