<template>
  <div class="d-flex justify-content-end">
    <b-btn
        class="ml-2"
        variant="success"
        @click="createFlapjack"
    >Create Flapjack
    </b-btn>
    <b-btn
        class="ml-2"
        variant="success"
        @click="createDineDonate"
    >Create Dine to Donate
    </b-btn>
    <b-btn
      class="ml-2"
      variant="success"
      @click="createTogetherWeCare"
    >Create Together We Care</b-btn>
  </div>
</template>

<script>
import {
  collection,
  getFirestore,
  onSnapshot,
  query
} from 'firebase/firestore'

import {mapActions, mapState} from 'vuex';

export default {
  name: "NewEvent",
  data() {
    return {
      users: [],
      selectedUser: null,
    }
  },

  computed: {
    ...mapState('fundraisers', ['fundraisers']),
    userOpts() {
      return this.users.map(u => {
        return {text: u.email, value: u.id}
      })
    }
  },

  mounted() {
    const usersRef = collection(getFirestore(), "users");
    this.getAllFundraisers()
    const q = query(usersRef);
    onSnapshot(q, (qSnap) => {
      this.users = [];
      qSnap.forEach((user) => {
        this.users.push({...user.data(), id: user.id});
      });
    });

  },

  methods: {
    ...mapActions('fundraisers', ['getAllFundraisers']),
    createDineDonate() {
      this.$router.push({
        name: 'Fundraiser',
        params: {
          id: this.fundraisers.find(x => x.name === 'Dining To Donate').id
        },
        query: {
          agreed: true,
          approved: true
        }
      })
    },

    createTogetherWeCare(){
      this.$router.push({
        name: 'Fundraiser',
        params: {
          id: this.fundraisers.find(x => x.name === 'Together We Care').id
        },
        query: {
          agreed: true,
          approved: true
        }
      })
    },

    createFlapjack() {
      this.$router.push({
        name: 'Fundraiser',
        params: {
          id: this.fundraisers.find(x => x.name === 'Flapjack Fundraiser').id
        },
        query: {
          agreed: true,
          approved: true
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
