<template>
  <b-container class="bv-example-row">
    <PageTitle title="Contact Us" />

    <div class="contact-form">
      <ContactForm />
    </div>

    <b-row>
      <b-col>
        <h2 class="contact-header">Employees</h2>
        <div class="text">
          For Employee Relations, HR related issues or reporting of an incident,
          Please contact <strong>Potomac Listens</strong> by clicking
          <a href="http://potomaclistens.tnwreports.com/" target="_blank"
            >Here</a
          >
          or contact them by phone at 855-333-3052.
        </div>

        <h2 class="contact-header">Loyal Guests</h2>
        <div class="text">
          If you are looking for general information about us or any of our
          locations, please contact us by using any of the methods below.
        </div>
        <h2 class="contact-header">Address</h2>
        <div class="text">
          13873 Park Center Road <br />Herndon, Virginia 20171
        </div>
      </b-col>

      <b-col cols="12" lg="4" offset-lg="3" class="d-flex align-items-right">
        <div class="social">
          <div>
            <h2 class="contact-header">Phone</h2>
            <div>Tel: 240-752-7565</div>

            <h2 class="contact-header">Fax</h2>
            <div>Fax: 703-787-6550</div>

            <h2 class="contact-header">Email</h2>
            <b-link class="email-link">info@potomacdining.com</b-link>
            <div class="pb-5">
              <SocialMediaIcons class="pt-5" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="border-top"></div>

    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.5423640771387!2d-77.4308794846486!3d38.93443627956483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b64706cafd730f%3A0x7421ae7d264363ac!2s13873%20Park%20Center%20Rd%2C%20Herndon%2C%20VA%2020171!5e0!3m2!1sen!2sus!4v1641065115430!5m2!1sen!2sus"
        width="600"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  </b-container>
</template>
<script>
import PageTitle from "@/components/shared/PageTitle";
import ContactForm from "@/components/shared/ContactForm";
import SocialMediaIcons from "@/components/shared/SocialMediaIcons";
export default {
  name: "IconSection",
  components: { PageTitle, ContactForm, SocialMediaIcons },
};
</script>
<style scoped>
.three-section {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  font-size: 18px;
}

.contact-header {
  font-size: 20px;
  color: #000080;
  letter-spacing: 2.5px;
  text-align: left;
  padding-top: 30px;
  font-weight: 500;
}

.text {
  text-align: left;
}
.social {
  text-align: left;
}
</style>
