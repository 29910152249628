<template>
  <div>
    <b-btn
        class="mb-4 mt-2 p-2"
        variant="success"
        size="sm"
        @click="() => {setting = !setting}"
        v-if="!setting"
    >
      Set Ticket Price
    </b-btn>
    <b-collapse
        v-model="setting"
    >
      <div class="d-flex">
        <b-input-group prepend="$">
          <b-form-input
              placeholder="0.00"
              type="number"
              v-model="price"
          />
        </b-input-group>
        <b-btn variant="success" @click="onSave" class="ml-2">Save</b-btn>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  updateDoc,
  doc,
  getFirestore
} from 'firebase/firestore';

export default {
  name: "SetTicketPrice",
  props: ['event'],
  data() {
    return {
      setting: false,
      price: "0"
    }
  },
  methods: {
    onSave() {
      updateDoc(doc(getFirestore(), `events/${this.event.id}`), {
        ticketPrice: this.price
      }).then(() => {
        this.setting = false;
        this.$emit('setPrice')
      })
    }
  }
}
</script>

<style scoped>

</style>
