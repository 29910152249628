<template>
  <b-container class="admin-border mb-5">
    <b-alert v-model="added" dismissible>Restaurant Added</b-alert>
    <b-collapse
        id="new-restaurant"
        title="New Restaurant"
        class="left"
        v-model="visible"
    >
      <b-form @submit="onSubmit" @reset="onReset">
        <b-form-group
            label="Street Address"
            label-for="street-address"
        >
          <b-form-input
              id="street-address"
              v-model="form.address"
              required
          />
        </b-form-group>

        <b-form-group
            label="City"
            label-for="city"
        >
          <b-form-input
              id="city"
              v-model="form.city"
              required
          />
        </b-form-group>


        <b-form-group
            label="State"
            label-for="state"
        >
          <b-form-select
              id="state"
              :options="states"
              v-model="form.state"
              required
          />
        </b-form-group>

        <b-form-group
            label="Phone"
            label-for="phone"
        >
          <b-form-input
              id="phone"
              v-model="form.phone"
              required
          />
        </b-form-group>

        <b-form-group
            label="Facebook Link"
            label-for="facebook"

        >
          <b-form-input
              placeholder="https://"
              id="facebook"
              v-model="form.facebookLink"
              required
          />
        </b-form-group>

        <b-form-group
            label="Google Maps Link"
            label-for="googleMaps"
            placeholder="https://"
        >
          <b-form-input
              placeholder="https://"
              id="googleMaps"
              v-model="form.googleMapsLink"
              required
          />
        </b-form-group>

        <div class="d-flex my-4">
          <b-btn class="ml-auto mr-3" size="sm" type="submit" variant="success">
            Create
          </b-btn>
          <b-btn type="reset" size="sm" class="mr-3"> Reset</b-btn>
          <b-btn class="cancel" size="sm" @click="visible = !visible">Cancel</b-btn>
        </div>
      </b-form>
    </b-collapse>
    <!--    <Temp/>  UNCOMMENT TO REUPLOAD ALL RESTAURANTS -->
    <b-btn
        variant="success"
        class="m-3"
        size="sm"
        @click="() => {visible = !visible}"
        v-if="!visible"
    >
      Add New Restaurant
    </b-btn>
  </b-container>
</template>

<script>
import {addDoc, collection, getFirestore} from 'firebase/firestore'
import Temp from "@/views/Admin/temp";

export default {
  name: "NewRestaurant",
  components: {Temp},
  data() {
    return {
      visible: false,
      form: {
        phone: '',
        address: '',
        city: '',
        state: '',
        facebookLink: '',
        googleMapsLink: '',
        visible: false,
        eventHidden: false
      },
      added: false,
    }
  },

  props: ['states'],

  methods: {
    onSubmit(event) {
      event.preventDefault();
      addDoc(collection(getFirestore(), "restaurants"), {
        ...this.form
      }).then(() => {
        this.form = {
          name: '',
          phone: '',
          address: '',
          city: '',
          state: '',
          facebookLink: '',
          googleMapsLink: '',
          visible: false,
          eventHidden: false
        }
        this.added = true;
        this.visible = false;
      })
    },

    onReset(event) {
      event.preventDefault();
      this.form = {
        name: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        facebookLink: '',
        googleMapsLink: '',
        visible: false,
        eventHidden: false
      }
    }
  }
}
</script>

<style scoped>
.left {
  text-align: left;
}

.cancel {
  background-color: red;
}
</style>
