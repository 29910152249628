<template>
  <div>
    <div
      :style="`background-image: url(/images/BourbonStreetSteak.png)`"
      class="main-image"
    >
      <div class="background-image-cover">
        <b-container class="d-flex">
          <div class="header-text mb-auto p-5 mr-auto">
            <div>Welcome to</div>
            <h2>
              Potomac Family <br />
              Dining Group
            </h2>
          </div>
        </b-container>
      </div>
    </div>
    <div class="main-section">
      <div class="section-1 pt-5">WELCOME TO</div>
      <div class="section-2-border">
        <div class="section-2 pt-3">Potomac Family Dining Group</div>
      </div>
      <div class="section-3">
        Potomac Family Dining Group is an independent Applebee’s franchise,
        operating 49 restaurants in Virginia, Maryland, West Virginia,
        Pennsylvania, and North Carolina.
      </div>
    </div>

    <div class="pt-2">
      <b-button
        target="_blank"
        type="button"
        class="buttons btn btn-outline-primary potomac-btn"
        href="http://www.applebees.com/menu"
        variant="link"
      >
        APPLEBEE'S MENU
      </b-button>
    </div>
    <div>
      <b-button
        type="button"
        class="buttons btn btn-outline-primary mt-4 mb-5 potomac-btn"
        href=" https://www.applebees.com/en/"
        target="_blank"
        variant="link"
      >
        ORDER ONLINE
      </b-button>
    </div>

    <IconSection class="pt-5" />
    <Divider large />

    <div
      :style="`background-image: url(/images/table.jpeg)`"
      class="career-image"
    >
      <div class="career-image-cover">
        <div>
          <PageTitle class="pt-5 pb-3" title="Careers" />
          <b-container>
            <AlternatingCareer />
          </b-container>
        </div>
      </div>
    </div>

    <Contact class="pt-4" />

    <Divider large />
  </div>
</template>

<script>
import IconSection from "@/components/IconSection";
import Contact from "@/views/Contact";
import AlternatingCareer from "@/components/AlternatingCareer/AlternatingCareer";
import Divider from "@/components/shared/Divider";
import PageTitle from "@/components/shared/PageTitle";

export default {
  components: { Divider, IconSection, AlternatingCareer, Contact, PageTitle },
};
</script>

<style scoped>
.buttons {
  text-decoration: unset !important;
}

.header-text {
  color: white;
  text-align: left;
  font-size: x-large;
  margin-top: 200px;
}

.header-text h2 {
  font-size: 65px;
  letter-spacing: 3px;
}

@media only screen and (max-width: 600px) {
  .header-text h2 {
    font-size: 32px;
  }
}

.main-section {
  padding: 20px;
  max-width: 600px;
  margin: auto;
}

.main-image {
  min-height: 90vh;
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-image-cover {
  background-color: rgba(12, 37, 63, 0.58);
  height: 100%;
  width: 100%;
  min-height: 90vh;
}

.career-image {
  min-height: 90vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.career-image-cover {
  background-color: rgba(255, 255, 255, 0.87);
  height: 100%;
  width: 100%;
  min-height: 90vh;
}

.section-1 {
  color: #000080;
  font-size: 25px;
}

.section-2 {
  color: #16345b;
  font-size: 40px;
}

.section-2-border {
  border-bottom: 5px solid #ffa500;
  padding-bottom: 15px;
}

.section-3 {
  padding-top: 60px;
  padding-bottom: 60px;
  color: #232b2b;
  font-size: 20px;
}
</style>
