import {debounce} from 'lodash';
import {httpsCallable, getFunctions} from 'firebase/functions';

export default {
  namespaced: true,
  state() {
    return {
      loading: true,
      events: [],
      allEvents: []
    }
  },

  getters: {
    getById: state => id => {
      return state.allEvents.find(event => event.id === id)
    }
  },

  mutations: {
    import(state, data) {
      state.events = data.map(e => {
        if (e.data){
          return {
            ...e,
            date: e.data
          }
        } else {
          return e
        }
      }).filter(e => {
        return e.date >= Date.now();
      });
      state.loading = false;
    },

    importAll (state, data){
      state.allEvents = data.map(e => {
        if (e.data) {
          return {
            ...e,
            date: e.data
          }
        } else {
          return e
        }
      })
    }
  },

  actions: {
    getEvents({state, commit}, restaurant) {
      httpsCallable(getFunctions(), "getEvents")({restaurant}).then(result => {
        commit('import', result.data.events)
        commit('importAll', result.data.events)
      })
        .catch(e => {
          commit('import', []);
          commit('importAll', [])
          this.$notify({
            group: 'main-notifications',
            type: 'error',
            title: 'Error Loading Events',
            text: 'Something went wrong, please notify us'
          })
        })
    }
  }
}
