<template>
  <div>
    <Section
        v-for="(item, ind) in items"
        :key="`${ind}-${item.title}`"
        :left="ind % 2 === 0"
        :image="item.image"
        :title="item.title"
        :alt="item.alt"
        :items="item.items"
        :id="`section${ind}`"
        :requirements="item.requirements"
        :benefits="item.benefits"
        :description="item.description"
    />
  </div>
</template>

<script>
import Section from "@/components/AlternatingCareer/Section";
import Divider from "@/components/shared/Divider";

export default {
  name: "AlternatingCareer",
  components: {Section, Divider},
  data() {
    return {
      items: [
        {
          image: "order.jpg",
          alt: "hostess smiling",
          id: "team",
          title: "Hourly Team Member",
          description:
              "Are you looking for a new job that can offer you a flexible schedule, a fun working environment, and a chance to make new friends while developing your long-term career goals? Potomac Family Dining Group and Applebee’s may be the perfect fit for you. Join our team!",
          requirements: [],
          benefits: [
            "Competitive Wages",
            "Flexible Scheduling",
            "Dining Privileges",
            "Rain Instant Pay (*for non-tipped team members)",
          ],
          items: [
            "Host/Hostess",
            "Bartender",
            "Server",
            "Line Cook/BOH",
            "Carside To Go Specialist",
          ],
        },
        {
          image: "restaurant-employee.jpg",
          alt: "Employee smiling in restaurant",
          id: "management",
          title: "Management",
          description:
              "If you are looking for a great opportunity to be a part of an organization that recognizes entrepreneurial spirit while building upon the strongly established Applebee's brand, then Potomac Family Dining is the place for you! Apply to be a member of our field support team and find yourself working for a company that values you and your long-term career goals.",
          requirements: [
            "2+ Years of Restaurant Experience",
            "Confident Leadership Skills",
            "Ability to Communicate and Make Sound, Informed Decisions",
            "Commitment to Food Safety and Standards of Operational Excellence",
            "Dedication to Providing Great Customer Service",
          ],
          benefits: [
            "Competitive Salary",
            "Monthly Bonuses",
            "Paid Vacation",
            "Health Insurance",
            "401K Retirement Plan",
            "Dining Privileges",
            "Rain Instant Pay",
          ],
          items: [
            "Hourly Manager",
            "Salaried Manager",
            "Salaried Kitchen Manager",
            "Salaried General Manager",
          ],
        },
        {
          image: "three-employees.jpg",
          alt: "Three employees smiling in restaurant together",
          id: "corporate",
          title: "Corporate Staff",
          description:
              "If you are looking for a great opportunity to be a part of an organization that recognizes entrepreneurial spirit while building upon the strongly established Applebee's brand, then Potomac Family Dining is the place for you! Apply to be a member of our field support team and find yourself working for a company that values you and your long-term career goals.",
          requirements: [],
          benefits: [
            "Competitive Salary",
            "Paid Vacation",
            "Health Insurance",
            "401K Retirement Plan",
            "Dining Privileges",
            "Rain Instant Pay",
          ],
          items: ["Field Support Staff"],
        },
      ],
    };
  },
};
</script>

<style scoped></style>
