<template>
  <div>
    <b-btn variant="link" class="p-0" :disabled="generating" @click="generate">
      Download Flyer
      <span v-if="generating"><Loading/></span>
    </b-btn>
    <vue-html2pdf
        :show-layout="false"
        filename="Dine & Donate Fundraiser"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        :paginate-elements-by-height="1400"
        @hasStartedGeneration="startedGenerating()"
        @hasGenerated="generated()"
        ref="pdf"
    >
      <section slot="pdf-content">
        <b-img
            :src="require('../../assets/images/dine-to-donate-flyer.png')"
            class="header-image"
        />
        <div class="content">
          <b-row>
            <b-col cols="4">
              <b-img
                  :src="require('../../assets/images/burger.jpg')"
                  class="content-image"
              />
            </b-col>
            <b-col cols="6" offset="1" class="content-section">
              Dine in or order to go carside at applebee's on {{ event.date | moment("MM/DD/YYYY") }} and present this
              flyer to donate 20% of your check total to {{ event.orgInfo.name }}!
            </b-col>
            <b-col cols="7" class="content-section-right mt-4">
              only at the applebee's located at {{ restaurant.address }} {{ restaurant.city }}, {{ restaurant.state }}.
              <div class="red mt-2">
                present this flyer to your server to participate.
              </div>
              <b-img :src="require('../../assets/images/applebees-logo-dark.png')" class="logo"/>
            </b-col>
            <b-col cols="5" class="mt-4">
              <b-img
                  :src="require('../../assets/images/steak.jpg')"
                  class="content-image-right"
              />
            </b-col>
          </b-row>
        </div>
        <div class="footer p-3 small">
          Donation percentage excludes tax, tip and alcohol sales. Valid at participating restaurants during specified
          hours. Must present flyer in order for organization to receive credit for purchase. Flyers are not to be
          distributed in the restaurant or within the perimeter of the parking lot. you must bring this flyer for your
          donation to be counted. If ordering To Go, please mention this fundraiser.
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import pdf from "@/components/shared/mixins/pdf";
import Loading from "@/components/shared/Loading";

export default {
  component: Loading,
  name: "DineToDonate",
  mixins: [pdf],
}
</script>

<style scoped>
.content-image {
  width: 300px;
  margin-top: 20px;
  border: 5px solid #e00001;
  border-left: none;
}

.header-image {
  width: 100%;
  height: auto;
}

.content {
  height: 760px;
}

.content-section-right {
  font-size: 24px !important;
  font-weight: 700;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  text-transform: uppercase;
}


.content-section {
  font-weight: 700;
  text-align: center;
  width: 300px;
  font-size: 28px;
  margin: auto;
  text-transform: uppercase;
}

.content-image-right {
  border: 5px solid #e00001;
  border-right: none;
  width: 400px;
}

.red {
  color: #e00001;
  font-size: 20px;
}

.footer {
  height: 75px;
  font-size: 12px;
  text-align: center;
}
</style>
