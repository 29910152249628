<template>
  <div>
    <b-link href="https://www.facebook.com/PotomacFamDining" target="_blank"
      ><b-icon-facebook class="icon mr-4"></b-icon-facebook
    ></b-link>

    <b-link
      href="https://www.instagram.com/potomacfamilydining/"
      target="_blank"
      ><b-icon-instagram class="icon mr-4"></b-icon-instagram
    ></b-link>

    <b-link href="https://twitter.com/PotomacDining" target="_blank"
      ><b-icon-twitter class="icon mr-4"></b-icon-twitter
    ></b-link>

    <b-link
      href="https://www.linkedin.com/company/potomac-family-dining-group-applebee%27s-franchisee-"
      target="_blank"
      ><b-icon-linkedin class="icon mr-4"></b-icon-linkedin
    ></b-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.icon {
  background-color: #fffdfa;
  color: #007bff;
  font-size: 35px;
}

.icon:hover {
  background-color: #fffdfa;
  color: #ffa500;
  font-size: 35px;
}

.icon-border {
  border: 1px solid #ffa500;
  border-radius: 50%;
  padding: 20px;
  height: 75px;
  width: 75px;
}
</style>
