<template>
  <div>
    <b-btn variant="link" class="p-0" :disabled="generating" @click="generate">
      Download Flyer
      <span v-if="generating"><Loading/></span>
    </b-btn>
    <vue-html2pdf
        :show-layout="false"
        filename="Together We Care"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        :paginate-elements-by-height="1400"
        @hasStartedGeneration="startedGenerating()"
        @hasGenerated="generated()"
        ref="pdf"
    >
      <section slot="pdf-content">
       <div class="header py-2 px-5 d-flex">
         <div>
           <div class="h1">TOGETHER</div>
           <div class="h2">
             <span class="gold">WE CARE</span>
             TUESDAYS
           </div>
         </div>
         <div class="ml-20 text-center">
           <b-img
               :src="require('../../assets/images/together_we_care_logo.png')"
               class="header-image"
           />
         </div>
       </div>
        <div class="sub-header text-uppercase banner text-center font-weight-bold">
          Serving Our Neighborhoods Together
        </div>
        <div class="text-center container py-2 px-5 content">
          <div class="subtitle font-weight-bold mt-2 mb-2 text-uppercase">
            Support a non profit by placing an online order at the participating Applebees on the date listed.
          </div>
          <div class="d-flex align-items-center">
            <div class="h3 label-header">Organization</div>
            <div class="flex-grow-0 ml-2 event-data mb-2 border-bottom border-dark flex-grow-1 text-left">{{event.orgInfo.name}}</div>
          </div>
          <div class="d-flex align-items-center">
            <div class="h3 label-header">Fundraiser Date</div>
            <div class="flex-grow-0 ml-2 event-data mb-2 border-bottom border-dark flex-grow-1 text-left">{{new Date(event.date).toLocaleDateString()}}</div>
          </div>
          <div class="d-flex align-items-center">
            <div class="h3 label-header">Restaurant Location</div>
            <div class="flex-grow-0 ml-2 event-data mb-2 border-bottom border-dark flex-grow-1 text-left">{{restaurant.address}}</div>
          </div>
          <div class="menu-section p-3 text-uppercase mt-3">
            <div class="h3 label-header d-flex justify-content-center">
              <div class="font-weight-bold">
                <div class="how-to-participate">
                  How To Participate
                </div>
              </div></div>
            <div class="mt-3 primary-text text-center">
              <div class="font-weight-bold"><span class="red">1)</span> Visit applebees.com or the applebees mobile app to place an order for to go, carside, or delivery.</div>
            </div>
            <div class="mt-3 primary-text text-center">
              <div class="font-weight-bold">
                <div><span class="red">2)</span> Choose any combination of the eligible<br/> entrees below and put into your cart.</div>
                <div class=" mt-3 ">
                  Bacon Cheeseburger
                </div>
                <div>Fiesta Lime Chicken</div>
                <div>Chicken Tenders Platter</div>
                <div>Three cheese chicken penne</div>
                <div class="mb-3">Chicken ceasar salad</div>
              </div>
            </div>
            <div class="mt-3 primary-text text-center">
              <div class="font-weight-bold"><span class="red">3)</span> Proceed to checkout.</div>
            </div>
          </div>
          <div class="text-center mt-4 h3 label-header text-uppercase font-weight-bolder">
            50% of the proceeds from each eligible entree will be<br/> donated to the nonprofit organization listed above.
          </div>
        </div>
        <div class="footer d-flex p-5 text-center">
          <div>
            <div class="gold">Only at participating Potomac Family Dining Applebee's restaurants in MD, VA, WVA, PA, & NC.</div>
            <div>To host a TWC Fundraiser, please contact your local Applebee's locaiton.</div>
          </div>
          <div class="ml-5">
            <b-img
                :src="require('../../assets/images/applebees-flyer-logo.png')"
                class="header-image m-auto"
            />
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import pdf from "@/components/shared/mixins/pdf";
import Loading from "@/components/shared/Loading";

export default {
  components: Loading,
  name: "TogetherWeCare",
  mixins: [pdf],
}
</script>

<style scoped>
.pdf-content{
  height: 1400px;
}

.header {
  background: black;
  color: white;
  height: 200px;
}

.footer{
  background: black;
  color: white;
  height: 200px;
}

.primary-text{
  font-size: 24px;
  line-height: 24px;
}

.menu-section{
  background: #ffaf18;
  border-radius: 50px;
}

.underline{
  text-decoration: underline;
}

.red {
  color: #be2026;
}

.how-to-participate{
  border-bottom: 2px #be2026;
}

.subtitle{
  font-size: 24px;
  line-height: 24px;
}

.sub-header{
  background: #be2026;
  color: white;
  height: 50px;
  font-style: italic;
}

.org{
  background: green;
}

.label-header{
  color: #be2026;
}

.event-data {
  font-size: 18px;
}

.h1{
  font-size: 84px;
  font-weight: bolder;
}

.h2{
  font-size: 42px;
}

.banner {
  font-size: 28px;
}

.h3{
  font-size: 20px;
}

.gold{
  color: #f3b91c;
}

.gold-dark {
  color: #b48914;
}

.header-content {
  position: absolute;
  top: 310px;
  left: 10px;
  width: 58%;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.larger-content {
  font-size: 28px;
}

.logo {
  padding-top: 25px;
  height: 100%;
  width: 100%;
}

.header-image {
  width: 80%;
  height: auto;
}

.content {
  height: 672px;
}


.title-line {
  font-weight: 900;
  font-size: 36px;
  padding-top: 30px;
}

.desc-line {
  text-transform: uppercase;
  font-size: 20px;
}
</style>
