<template>
  <div class="d-flex flex-column align-items-center">
    <PageTitle title="Coaches Eat Free"/>

    <div>
      <p>
        Applebee's is proud to be the place where the neighborhood goes to hang
        out after the game.
      </p>
      <p>
        We welcome coaches and their teams after the game, whether it's little
        league or college, local teams or visitors from out of town.
      </p>
      <p>
        Each coach in attendance who brings in their team after the game will
        receive a complimentary entree and non-alcoholic beverage on us. Just
        print out the flyer below and bring it in with you when you visit.
      </p>
      <p>We look forward to seeing you and your team at Applebee's!</p>I am
    </div>

    <div class="documents">
      <h2>Documents:</h2>
      <a
          href="https://potomacdiningserves.com/documents/17_pagedoc_CoachesEatFree%20copy.pdf"
          target="_blank"
      >Coaches Eat Free Flyer</a
      >
    </div>

    <div>
      <b-img
          :src="require(`@/assets/images/logo-diamond.png`)"
          alt="better together logo"
          class="my-5"
      ></b-img>
    </div>

    <Divider large/>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle";
import Divider from "@/components/shared/Divider";

export default {
  components: {Divider, PageTitle},
};
</script>

<style scoped>
.about-image {
  box-shadow: 5px 5px 10px 5px rgba(54, 49, 49, 0.13);
}

p {
  max-width: 560px;
  text-align: left;
}
</style>
