<template>
  <div>
    <svg width="150" height="150" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 631 508">
      <g id="potomac-logo">
        <Transition>

          <g id="left" v-if="showLeft">
            <path d="M196.485 144 86 254l110.485 110 110.484-110-110.484-110Z" fill="#8DC63F"/>
            <path d="M307 253.993H86.013l110.493-109.99L307 253.993Z" fill="#B8D881"/>
            <path
                d="M269.431 234.434c.492 2.297-24.938 3.634-32.444 18.957-2.401 4.894-1.607 12.254.094 26.768 2.515 21.666 5.937 30.118 3.782 31.229-3.328 1.562-15.126-15.323-22.349-28.613a207.218 207.218 0 0 1-11.23-25.262 346.819 346.819 0 0 1-9.019-26.241 198.8 198.8 0 0 1-15.126 42.505c-10.607 21.648-24.863 40.679-28.039 39.098-2.401-1.167 3.592-12.894 4.367-35.446.435-12.386.587-18.711-3.157-24.773-8.508-13.779-29.325-14.457-28.89-16.565.321-1.581 11.855 1.129 28.152-1.412 13.992-2.165 21.233-6.4 23.427-7.774a33.51 33.51 0 0 0 12.932-12.387c4.103-7.529 2.987-14.626 4.878-14.814 1.891-.188 2.458 6.099 7.809 14.325a47.65 47.65 0 0 0 10.739 11.652c8.149 6.118 16.903 7.059 27.075 8.019 15.92 1.544 26.773-.771 26.999.734Z"
                fill="#fff"/>
            <path
                d="M166.236 202.622c-4.689 1.43-7.09 7.36-5.861 12.141 1.342 5.196 7.09 9.412 12.195 7.775 4.481-1.469 6.618-6.871 5.861-11.182-.964-5.534-6.92-10.429-12.195-8.734Z"
                fill="#fff"/>
            <path d="m161.32 186.075-63.15 60.746 28.531-.49-11.949-6.306 46.568-53.95Z" fill="#CBE2A1"/>
            <path
                d="M230.01 199.233c1.545.3 3.009.922 4.295 1.827a11.097 11.097 0 0 1 3.162 3.423 11.019 11.019 0 0 1 .991 9.038 11.043 11.043 0 0 1-2.345 4.022 11.154 11.154 0 0 1-12.928 2.585 11.087 11.087 0 0 1-5.495-5.619 11.023 11.023 0 0 1-.376-7.834 11.075 11.075 0 0 1 4.932-6.116 11.164 11.164 0 0 1 7.764-1.326Z"
                fill="#fff"/>
          </g>
        </Transition>
        <Transition>

          <g id="right" v-if="showRight">
            <path
                d="m500.524 253.5 3.29 6.07-11.232 5.275-5.824-11.345h-5.56l6.032 10.702a23.918 23.918 0 0 1-4.652 9.284 23.498 23.498 0 0 1-8.036 6.334l-25.661-26.32h-59.339l-4.312 10.948 8.302 9.038-1.475 2.931-10.722-11.572c1.456-3.782 2.893-7.563 4.349-11.345h-5.2a2550.637 2550.637 0 0 1-3.782 11.194l-11.233-3.631 2.913-7.563H324L434.509 364 545 253.5h-44.476ZM403.8 285.342c-1.891 1.474-5.408 2.042-7.148 0a5.333 5.333 0 0 1 1.286-7.318 5.671 5.671 0 0 1 7.564-.378c1.834 1.985.624 5.88-1.702 7.639v.057Zm3.782 7.563a5.276 5.276 0 0 1-1.097-5.673l10.438-10.437a4.972 4.972 0 0 1 5.843 7.866l-9.322 9.341a5.298 5.298 0 0 1-5.806-1.211l-.056.114Zm9.814 8.811a5.372 5.372 0 0 1-1.191-5.994l11.346-11.042a5.504 5.504 0 0 1 6.769 1.55 5.18 5.18 0 0 1-.397 6.769l-10.154 9.889a5.857 5.857 0 0 1-6.316-1.285l-.057.113Zm23.978-.094-7.942 7.979a4.465 4.465 0 0 1-5.012-.964 4.537 4.537 0 0 1-.926-4.898l8.906-9.038a4.256 4.256 0 0 1 5.333 1.267 4.368 4.368 0 0 1-.303 5.54l-.056.114Zm31.882-9.795c-2.591 1.456-4.728-.964-7.072.492-2.345 1.456-1.154 5.275-3.669 6.844-2.515 1.57-5.862-1.531-8.283.227-1.796 1.324-.567 3.442-2.685 5.673-1.796 1.891-5.105 2.798-7.072 1.475-1.04-.7-1.891-2.137-1.475-2.931.662-1.267 3.782.246 5.673-1.229.964-.794 1.664-2.572.737-6.826-3.668-3.983-7.33-7.967-10.986-11.95a1.9 1.9 0 0 1-.56-1.343 1.895 1.895 0 0 1 .56-1.342 2.009 2.009 0 0 1 2.305-.283c.243.131.456.311.626.529l13.407 14.143a4.613 4.613 0 0 0 7.091-5.124l-11.724-14.635a2.385 2.385 0 0 1 .492-2.931 2.398 2.398 0 0 1 2.808.2c.257.215.467.482.614.784l11.706 14.143c3.914 1.607 5.673 1.21 6.353.492 1.438-1.324.549-4.349 1.891-4.879a1.701 1.701 0 0 1 1.702.492c1.551 1.342.454 6.296-2.382 7.866l-.057.113Z"
                fill="#FBB040"/>
            <path
                d="M500.524 253.5H545L434.491 143 324 253.5h44.382c4.16-10.74 8.314-21.486 12.461-32.239l9.455 2.931a25891.74 25891.74 0 0 1-9.89 29.308h5.219l10.287-27.114a10.671 10.671 0 0 0 6.335 3.176c1.381.151 2.648 0 7.072-1.456 3.782-1.21 6.108-2.099 7.092-2.439a24.89 24.89 0 0 1 14.144-.246l-3.895 2.931a15.85 15.85 0 0 0-6.108 0c-3.877.756-4.728 2.515-9.455 3.895-2.217.649-4.516.98-6.827.983a23.03 23.03 0 0 1-6.353-.983 5648.596 5648.596 0 0 0-8.377 21.253h59.339l-11.195-11.496a47.477 47.477 0 0 0-10.967 5.124c-4.577 2.931-6.373 5.256-10.495 5.861-2.364.341-6.619.303-7.81-1.89-1.57-2.988 3.214-8.055 3.895-8.793 3.48-3.668 5.56-3.101 12.197-7.071 6.637-3.971 6.713-5.673 12.216-8.055 4.198-1.778 7.205-3.063 10.722-2.194 3.517.87 7.81 4.179 10.495 6.335a9.313 9.313 0 0 0 4.633 2.685 8.694 8.694 0 0 0 6.353-2.194c4.085 7.248 8.176 14.497 12.273 21.745h5.56l-13.616-26.982 10.495-4.387 16.887 31.312Zm-31.844-67.37 66.373 64.174-36.042.19c-.681-1.532-1.343-3.064-2.023-4.576l14.882-3.423-43.19-56.365Z"
                fill="#FEC77B"/>
            <path
                d="M390.355 224.135c-3.29 9.77-6.587 19.539-9.89 29.308a2550.637 2550.637 0 0 1-3.782 11.194l-11.214-3.574 2.913-7.563c4.16-10.74 8.314-21.486 12.461-32.239l9.512 2.874Zm40.259 1.229-3.895 2.931a15.89 15.89 0 0 0-6.108 0c-3.877.757-4.728 2.515-9.455 3.895-2.218.65-4.516.981-6.827.984a23.07 23.07 0 0 1-6.353-.984 3353.76 3353.76 0 0 0-8.434 21.31l-4.312 10.948 8.302 9.038-1.475 2.931-10.722-11.572c1.456-3.782 2.893-7.563 4.349-11.345l10.287-27.114a10.671 10.671 0 0 0 6.335 3.176c1.38.151 2.647 0 7.072-1.456 3.782-1.21 6.108-2.099 7.091-2.439a24.895 24.895 0 0 1 14.145-.303Z"
                fill="#fff"/>
            <path
                d="M487.23 264.145a23.91 23.91 0 0 1-4.652 9.284 23.513 23.513 0 0 1-8.036 6.335L448.881 253.5l-11.195-11.496a47.477 47.477 0 0 0-10.967 5.124c-4.577 2.931-6.373 5.256-10.495 5.861-2.364.341-6.619.303-7.81-1.89-1.57-2.988 3.214-8.055 3.895-8.793 3.48-3.668 5.56-3.101 12.197-7.071 6.637-3.971 6.713-5.673 12.216-8.055 4.198-1.778 7.205-3.063 10.722-2.194 3.517.87 7.81 4.179 10.495 6.335a9.313 9.313 0 0 0 4.633 2.685 8.694 8.694 0 0 0 6.353-2.194c4.085 7.248 8.176 14.497 12.273 21.745l6.032 10.588Zm16.584-4.632-11.232 5.332-5.824-11.345-13.616-26.925 10.495-4.387 17.019 31.255 3.158 6.07Zm-30.501 32.201c-2.591 1.456-4.728-.965-7.073.491-2.345 1.456-1.153 5.276-3.668 6.845s-5.862-1.532-8.283.227c-1.796 1.323-.567 3.441-2.685 5.672-1.796 1.891-5.106 2.799-7.072 1.475-1.04-.699-1.891-2.136-1.475-2.931.662-1.266 3.782.246 5.673-1.229.964-.794 1.664-2.571.737-6.825a9178 9178 0 0 1-10.986-11.951 1.887 1.887 0 0 1 0-2.684 1.988 1.988 0 0 1 1.519-.518 2.007 2.007 0 0 1 1.412.763l13.407 14.144a4.612 4.612 0 0 0 7.064-2.294 4.613 4.613 0 0 0 .027-2.83l-11.724-14.635a2.375 2.375 0 0 1-.267-1.552 2.378 2.378 0 0 1 .758-1.379 2.397 2.397 0 0 1 1.93-.268c.322.093.622.252.879.467.257.216.466.483.614.784l11.705 14.143c3.915 1.608 5.673 1.211 6.354.492 1.437-1.324.548-4.349 1.891-4.878a1.701 1.701 0 0 1 1.702.491c1.494 1.456.397 6.41-2.439 7.98Z"
                fill="#fff"/>
            <path
                d="m535.053 250.248-36.042.189c-.681-1.532-1.343-3.063-2.023-4.576l14.882-3.422-43.19-56.366 66.373 64.175Z"
                fill="#FFD393"/>
            <path
                d="M403.8 285.285c-1.891 1.475-5.408 2.042-7.148 0a5.333 5.333 0 0 1 1.286-7.318 5.674 5.674 0 0 1 7.564-.378c1.834 2.042.624 5.937-1.702 7.696Zm19.023-.738-9.322 9.341a5.216 5.216 0 0 1-5.782-1.018 5.217 5.217 0 0 1-1.177-5.751l10.438-10.437a4.973 4.973 0 0 1 5.843 7.865Zm11.157 8.339-10.155 9.889a5.857 5.857 0 0 1-6.372-1.172 5.37 5.37 0 0 1-1.192-5.994l11.346-11.043a5.501 5.501 0 0 1 6.77 1.551 5.18 5.18 0 0 1-.397 6.769Zm7.45 8.792-7.998 8.093a4.465 4.465 0 0 1-5.012-.964 4.537 4.537 0 0 1-.926-4.898l8.906-9.038a4.256 4.256 0 0 1 5.333 1.267 4.365 4.365 0 0 1-.303 5.54Z"
                fill="#fff"/>
          </g>
        </Transition>
        <Transition>

          <g id="bottom" v-if="showBottom">
            <path
                d="M341.862 446.161a26.78 26.78 0 0 1-2.823 1.148 2.893 2.893 0 0 0 0 .395v9.017a26.78 26.78 0 0 0 2.823-1.148 2.86 2.86 0 0 0 0-.414v-8.998Z"
                fill="#fff"/>
            <path
                d="M337.439 461.446c.358-.339.734-.659 1.111-.979L426 372.991 316 263 206 372.991 316 483l19.463-19.464c.61-.742 1.27-1.44 1.976-2.09Z"
                fill="#1C75BC"/>
            <path
                d="M337.439 461.446c.358-.339.734-.659 1.111-.979L426 372.991 316 263v220l19.463-19.464c.61-.742 1.27-1.44 1.976-2.09Z"
                fill="#0080C5"/>
            <path
                d="M285.507 454.971v-18.034a40.934 40.934 0 0 0-1.882-7.699c-2.033-5.648-4.066-7.436-9.637-17.582a51.866 51.866 0 0 1-4.198-8.961 39.263 39.263 0 0 1-1.882-8.395 34.958 34.958 0 0 1 2.39-16.434 58.935 58.935 0 0 1 6.118-11.125c5.27-8.189 10.315-15.831 15.66-15.06l.565.113c3.219.847 6.042 17.902-.339 106.603l-6.795-3.426Zm54.341-86.705a3.016 3.016 0 0 1-2.127.847 3.202 3.202 0 0 1-2.127-.904l-.414-39.87a2.048 2.048 0 0 0-2.014-1.675 2.031 2.031 0 0 0-1.882 1.581l-.226 40.077a2.744 2.744 0 0 1-4.442-.188c.546-24.754.791-34.505.866-36.312 0-1.017 0-2.014.113-3.031.188-2.108.47-2.974.132-3.162-.339-.189-2.278 1.769-2.429 1.882a11.071 11.071 0 0 0-1.487 2.428c-.545 1.883-1.073 3.765-1.543 5.968a98.66 98.66 0 0 0-1.882 11.765l-1.073 9.412a67.771 67.771 0 0 0-.283 13.987 34.29 34.29 0 0 0 .866 5.816 64.999 64.999 0 0 0 4.122 9.958 219.253 219.253 0 0 0 10.202 19.446l.113 58.544h.189a.95.95 0 0 0 0-.226c.263-.207.489-.452.734-.659a17.57 17.57 0 0 1 2.315-2.56c.79-.753 1.637-1.469 2.466-2.184l-.189-90.94Z"
                fill="#fff"/>
            <path
                d="M318.24 418.489c.15 16.829.295 33.67.433 50.525l13.966-15.06v-10.203l-9.166 4.857a94562.512 94562.512 0 0 0-5.233-30.119Zm25.015 21.083 42.69-36.933-42.746 44.444"
                fill="#8CBBE5"/>
            <path
                d="M297.046 464.326v-27.521a42.474 42.474 0 0 1 1.882-7.699c2.033-5.647 4.065-7.436 9.637-17.582a51.966 51.966 0 0 0 4.198-8.96 39.285 39.285 0 0 0 1.882-8.396 34.768 34.768 0 0 0-2.391-16.434 58.874 58.874 0 0 0-6.117-11.125c-5.27-8.188-10.315-15.831-15.642-15.059a4.838 4.838 0 0 0-.583.113c-3.219.847-6.024 17.902.357 106.602l6.777 6.061Zm59.272-107.129-1.073-9.412c-.4-3.961-1.028-7.896-1.882-11.784-.489-2.09-.998-4.085-1.562-5.968a10.614 10.614 0 0 0-1.487-2.409c-.132-.188-1.882-2.259-2.428-1.883-.546.377 0 1.055.131 3.163.095.998 0 2.014.113 3.031 0 1.882.32 11.558.866 36.312a2.794 2.794 0 0 1-2.174 1.2 2.791 2.791 0 0 1-2.268-1.012c-.075-13.365-.144-26.724-.207-40.077a2.032 2.032 0 0 0-3.99 0l-.414 39.87a2.966 2.966 0 0 1-3.281.662 2.96 2.96 0 0 1-.973-.662v95.138a20.26 20.26 0 0 1 1.882-1.882c1.242-1.186 2.597-2.259 3.877-3.407l.094-51.636a210.697 210.697 0 0 0 10.202-19.426 65.473 65.473 0 0 0 4.141-9.958c.434-1.914.717-3.859.847-5.817a67.91 67.91 0 0 0-.414-14.043Z"
                fill="#fff"/>
          </g>
        </Transition>
        <Transition>

          <g id="top" v-if="showTop">
            <g id="Vector">
              <path
                  d="M315.009 26 205 136.009l110.009 109.916 110.01-109.991L315.009 26Zm5.648 64.417V37.464l67.767 68.502-58.75-45.668a10913.415 10913.415 0 0 1-8.998 30.119h-.019Z"
                  fill="#BE1E2D"/>
              <path d="m315.009 26 110.01 110.009L315.009 246V26Z" fill="#EF4136"/>
            </g>
            <g id="Vector_2" fill="#fff">
              <path
                  d="M315.198 185.122c-.076-23.944-.145-47.895-.207-71.852-9.111-9.544-21.761-13.63-32.905-10.41a30.847 30.847 0 0 0-20.613 21.874c-4.254 18.993 16.396 44.67 53.348 60.614l.377-.226Z"/>
              <path
                  d="M368.32 124.602a30.854 30.854 0 0 0-20.613-21.874c-11.144-3.219-23.794.866-32.905 10.41 0 24.095-.069 48.178-.207 72.248l.603-.264h.244c36.651-16.076 57.095-41.602 52.878-60.52Z"/>
            </g>
            <path id="Vector_3" d="m388.443 105.966-58.769-45.668-8.998 30.119V37.464l67.767 68.502Z" fill="#F69481"/>
          </g>
        </Transition>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      showTop: false,
      showBottom: false,
      showRight: false,
      showLeft: false,
    }
  },

  methods: {
    showTopTimer() {
      this.showTop = !this.showTop
      setTimeout(() => {
        this.showRightTimer()
      }, 250)
    },
    showRightTimer() {
      this.showRight = !this.showRight
      setTimeout(() => {
        this.showBottomTimer()
      }, 250)
    },
    showBottomTimer() {
      this.showBottom = !this.showBottom
      setTimeout(() => {
        this.showLeftTimer()
      }, 250)
    },
    showLeftTimer() {
      this.showLeft = !this.showLeft
      setTimeout(() => {
        this.showTopTimer()
      }, 250)

    },
  },

  mounted() {
    this.showTopTimer();
  }
}
</script>

<style>
.v-enter-active, .v-leave-active {
  transition: all 0.25s ease-out;
}

.v-enter-from, .v-leave-to {
  opacity: 0;
}

/*#top, #right, #bottom, #left {*/
/*  opacity: 0;*/
/*  animation: 4s steps(20) opacity infinite;*/
/*}*/

/*#top {*/
/*  !*animation-delay: 0.5s*!*/
/*}*/

/*#right {*/
/*  animation-delay: 1s*/
/*}*/

/*#bottom {*/
/*  animation-delay: 2s*/
/*}*/

/*#left {*/
/*  animation-delay: 3s*/
/*}*/

/*@keyframes opacity {*/
/*  0% {*/
/*    opacity: 0*/
/*  }*/
/*  50% {*/
/*    opacity: 1*/
/*  }*/
/*  100% {*/
/*    opacity: 0*/
/*  }*/
/*}*/


</style>
