module.exports = {
    apiKey: "AIzaSyDeuDsCWZumReoK-UFCpi4EhwWhp4eyiCE",
    authDomain: "potomacdining.com",
    databaseURL: "https://potomac-family-dining-default-rtdb.firebaseio.com",
    projectId: "potomac-family-dining",
    storageBucket: "potomac-family-dining.appspot.com",
    messagingSenderId: "414548014768",
    appId: "1:414548014768:web:b9deb3d55b0c0a65376d75",
    measurementId: "G-WKKDSN7SNB",
    appCheckSite: "6Ldf2RUmAAAAAIlFMY7h6M_yfMsFv3-KKgvIIVyU",
    siteKey: "6LcMaiclAAAAAK-5KgCu2v02b8SVE8neMgFWIv_I"
};
