<template>
  <b-col cols="12" lg="6">
    <h3 class="subtitle">
      {{ section.subtitle }}
    </h3>
    <p
        v-for="(para, ind) in section.paragraphs"
        :key="`${section.subtitle}_${ind}`"
    >
      {{ para }}
    </p>
  </b-col>
</template>
<script>
export default {
  name: 'PresidentTextSection',
  props: {
    section: {}
  }
}
</script>
<style scoped>

.subtitle {
  color: #000080;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.12em;
  padding-bottom: 10px;
  padding-top: 20px;
  border-bottom: 1px solid rgba(0, 0, 128, 0.21);
  margin-bottom: 10px;
}

img {
  width: 350px;
  box-shadow: 5px 5px 10px 5px rgba(54, 49, 49, 0.13);
}
</style>
