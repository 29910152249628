<template>
  <div>
    <div class="container">
      <PageTitle
          title="Message from Potomac Family Dining Group"
          subtitle="President and CEO, Dennis Benson"
      />

      <PresidentSection
          v-for="(section, ind) in presidentSections"
          :key="section.subtitle"
          :section="section"
          :right="ind % 2 === 1"
          class="mb-5"
      />
    </div>
    <Divider large/>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle";
import PresidentSection from "@/views/PresidentSection";
import Divider from "@/components/shared/Divider";
import Loading from "@/components/shared/Loading";

export default {
  components: {Loading, PresidentSection, PageTitle, Divider},
  data() {
    return {
      presidentSections: [
        {
          subtitle: "Welcome to the Potomac Family Dining Group Website",
          paragraphs: [
            "I am delighted that you have taken the time to discover more about Potomac Family Dining Group! We operate 49 Applebee’s restaurants in 5 states and were founded on a belief that truly happy Team Members create an excellent Guest Experience.  As such, our “Pyramid of Success” focuses intensely on People Excellence.  We create such excellence through our hiring, training, coaching, recognition, reward, and career path initiative.",
          ],
          image: require("@/assets/images/Headshot.jpg"),
          alt: "Image of President and CEO, Dennis Benson",
        },
        {
          subtitle: "As you will see...",
          paragraphs: [
            "Working at Potomac Family Dining Group is much more than “just a job”.  We treat our employees like family.  Our franchise is the top performing one in the entire Applebee’s system when it comes to new product and promotional training!  And it doesn’t stop there.  Many of our Managers began as hourly Team Members and chose to enter our Management Development Program.  Many General Managers have been promoted from within, and more than 50% our Area Directors have been General Managers for us.  We strive to be a People-focused, People-centric organization.",
          ],
          image: require("@/assets/images/logo.png"),
          alt: "Potomac Family Dining Group Logo",
        },
        {
          subtitle: "We take extreme pride",
          paragraphs: [
            "…in knowing that highly motivated and well-trained Team Members and Managers provide excellence in the total Guest Experience!  Our ultimate goal is to increase Sales through creating-and-keeping more Guests.  That happens when our Guests experience a fun, friendly environment with great food at reasonable prices.  In short – that’s what Applebee’s does!",
            "Whether you are a Guest, a current Team Members or someone interested in joining our company our website will provide you with the information needed to answer your inquiries.",
          ],
          image: require(`@/assets/images/Better Together Logo.png`),
          alt: "better together logo",
        },
      ],
    };
  },
};
</script>

<style scoped>


.right {
  text-align: right;
}

.left {
  text-align: left;
}

.subtitle {
  color: #000080;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.12em;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 128, 0.21);
  margin-bottom: 10px;
}
</style>
