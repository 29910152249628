<template>
  <div>
    <b-btn variant="link" class="p-0" :disabled="generating" @click="generate">
      Download Tickets
      <span v-if="generating"><b-spinner></b-spinner></span>
    </b-btn>
    <vue-html2pdf
        :show-layout="false"
        filename="FlapJack Tickets"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        :paginate-elements-by-height="1400"
        @hasStartedGeneration="startedGenerating()"
        @hasGenerated="generated()"
        ref="pdf"
    >
      <section slot="pdf-content">
        <div
            class="d-flex flex-wrap justify-content-between"
        >
          <div class="ticket" v-for="ind in 8" :key="ind">
            <div class="header">
              I'M ATTENDING AN APPLEBEE'S FLAPJACK FUNDRAISER BREAKFAST TO SUPPORT
            </div>
            <div class="title">
              {{ event.orgInfo.name }}
            </div>
            <b-row>
              <b-col cols="8">
                <div class="pl-2">
                  Cost: ${{ event.ticketPrice }}
                </div>
                <div class="pl-2">
                  Date: {{ event.date | moment("MM/DD/YYYY") }}
                </div>
                <div class="pl-2">
                  Time: 8 AM to 10 AM
                </div>
              </b-col>
              <b-col cols="4">
                <b-img
                    :src="require('../../assets/images/applebees-flyer-logo.png')"
                    class="logo"
                />
              </b-col>
            </b-row>
            <div class="pl-2">
              Location: {{ restaurant.address }}, {{ restaurant.city }}, {{ restaurant.state }}
            </div>
            <div class="small right pr-3">
              <div>
                <sup>*</sup>Tickets valid for pancake breakfast only.
              </div>
              <div>
                Applebee's menu items not included as part of purchase.
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div>
            PRINT AND DISTRIBUTE TICKETS TO GUESTS TO BE PRESENTED AT THE DOOR ON THE DAY OF THE EVENT.
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import pdf from "@/components/shared/mixins/pdf";

export default {
  name: "FlapjackTickets",
  mixins: [
    pdf
  ]
}
</script>

<style scoped>
.ticket {
  width: 390px;
  height: 250px;
}

.logo {
  padding-top: 25px;
  height: 100%;
  width: 100%;
}

.small {
  font-size: 8px;
}

.header {
  background-color: #a81f2e;
  color: #fdfcfd;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.title {
  color: black;
  font-size: 24px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 600;
}

.footer {
  border-top: 3px solid black;
  font-size: 30px;
  padding-top: 15px;
  text-align: center;
}
</style>
