import {getAuth, onAuthStateChanged} from "firebase/auth";

export default {
  data() {
    return {
      isEmployee: false
    }
  },

  mounted() {
    onAuthStateChanged(getAuth(), (user) => {
      if (!user) {
        this.isEmployee = false
        return;
      }
      user.getIdTokenResult().then(result => {
        this.isEmployee = result.claims.employee
      })
    })
  }
}
