<template>
  <div>
    {{restaurant}}
  </div>
</template>

<script>
  export default {
    name: "Restaurant",
    props: ['restaurant'],
  }
</script>

<style scoped>

</style>
