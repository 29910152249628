<template>
  <div>
    <b-btn @click="oneTime">One Time</b-btn>
  </div>
</template>

<script>
  import {addDoc, collection, getFirestore} from 'firebase/firestore'
  import locations from '@/assets/locations';

  export default {
    name: "temp",
    data() {
      return {
        states: {
          'VA': 'Virginia',
          'WV': 'West Virginia',
          'PA': "Pennsylvania",
          'MD': "Maryland",
          'NC': 'North Carolina'
        }
      }
    },
    methods: {
      oneTime() {
        let rests = locations.locations.map(loc => {
          return {
            phone: loc.phone,
            address: loc.address,
            city: loc.city,
            state: this.states[loc.State],
            facebookLink: loc.facebook,
            googleMapsLink: loc.googleMap,
            visible: true,
            eventHidden: false
          }
        })
        rests.forEach(rest => {
          addDoc(collection(getFirestore(), 'restaurants'), {
            ...rest
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>
