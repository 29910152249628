import { signOut, getAuth } from 'firebase/auth'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';

export default {
  namespaced: true,
  state() {
    return {
      user: {},
      currentUser: {},
      userID: '',
      admin: false,
      userData: {}
    }
  },

  mutations: {
    login(state) {
      state.user = getAuth();
    },
    clearUser(state) {
      state.user = {};
    },
    setAdmin(state, value) {
      state.admin = value;
    },
    setUserData(state, user) {
      state.userData = user;
    }
  },

  actions: {
    logout({ state, commit }) {
      signOut(getAuth())
        .then(() => {
          commit('clearUser')
        })
    },

    getUserData({ state, commit }) {
      console.log(getAuth())
      if (!getAuth().currentUser) {
        commit('setUserData', null);
        return;
      }
      const userRef = doc(getFirestore(), "users", getAuth().currentUser.uid);
      onSnapshot(userRef, result => {
        commit('setUserData', result.data())
      })
    }
  }
}
