<template>
  <div class="pb-5">
    <PageTitle title="Login"/>
    <div class="login">Please sign up or login in below.</div>
    <section id="firebaseui-auth-container"></section>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle";
import {getAuth, GoogleAuthProvider, EmailAuthProvider} from "firebase/auth";
import {auth} from "firebaseui";

export default {
  components: {PageTitle},
  mounted() {
    var ui = new auth.AuthUI(getAuth());
    var uiConfig = {
      signInSuccessUrl: "/",
      signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        EmailAuthProvider.PROVIDER_ID,
      ],
    };
    ui.start("#firebaseui-auth-container", uiConfig);
  },
};
</script>

<style scoped>

.login {
  font-size: 20px;
  padding-bottom: 20px;
}

</style>
