<template>
  <div>
    <b-btn
      variant="link"
      @click="$emit('closeEdit')"
    >
      Cancel
    </b-btn>
    <b-form
      @submit="onSubmit"
    >
      <b-datepicker
        v-model="form.date"
      />

      <b-form-group
        label="Org Name"
        label-for="org-name"
        class="mt-3"
      >
        <b-form-input
          id="org-name"
          type="text"
          v-model="form.orgInfo.name"
        />
      </b-form-group>

      <b-form-group
          label="Org Email (used for flyers)"
          label-for="org-name"
          class="mt-3"
      >
        <b-form-input
          id="org-email"
          type="email"
          v-model="form.orgInfo.email"
        />
      </b-form-group>

      <b-form-group
        label="Org Address"
        label-for="org-address"
        class="mt-3"
      >
        <b-form-input
          id="org-address"
          type="text"
          v-model="form.orgInfo.address"
        />
      </b-form-group>

      <b-form-group
        label="Contact Name"
        label-for="contact-name"
        class="mt-3"
      >
        <b-form-input
          id="contact-name"
          type="text"
          v-model="form.contact.name"
        />
      </b-form-group>

      <b-form-group
        label="Contact Email"
        label-for="contact-email"
        class="mt-3"
      >
        <b-form-input
          id="contact-email"
          type="text"
          v-model="form.contact.email"
        />
      </b-form-group>

      <b-form-group
        label="Contact Phone Number"
        label-for="contact-phone"
        class="mt-3"
      >
        <b-form-input
          id="contact-phone"
          v-model="form.contact.phoneNumber"
          type="text"
        />
      </b-form-group>

      <b-btn
        variant="success"
        type="submit"
        >Save</b-btn>
    </b-form>
  </div>
</template>

<script>
import {
  updateDoc,
    doc,
    getFirestore
} from 'firebase/firestore'
export default {
  name: "EditEvent",
  props: ['event'],
  data(){
    return {
      form: {
        date: null,
        orgInfo: {},
        contact: {}
      }
    }
  },

  mounted(){
    this.form.date = new Date(this.event.date);
    this.form.orgInfo = this.event.orgInfo
    this.form.contact = this.event.contact
  },

  methods: {
    onSubmit(event){
      event.preventDefault();
      updateDoc(doc(getFirestore(), `events/${this.event.id}`), {
        orgInfo: this.form.orgInfo,
        contact: this.form.contact,
        date: Date.parse(this.form.date) + (new Date(this.form.date).getTimezoneOffset() * 60000)
      })
          .then(() => {
            this.$notify({
              group: 'main-notifications',
              type: 'success',
              title: 'Applied',
              text: 'Updates Applied'
            })
            this.$nextTick(() =>{
              this.$emit('closeEdit')
            })
          })
          .catch((e) => {
            this.$notify({
              group: 'main-notifications',
              type: 'error',
              title: 'Error',
              text: 'Failed to apply updates'
            })
          })
    }
  }
}
</script>

<style scoped>

</style>