import Vue from 'vue'
import Vuex from 'vuex'

//modules
import user from './user';
import restaurants from "./restaurants";
import fundraisers from './fundraisers';
import events from './events';
import categories from './categories';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    restaurants,
    fundraisers,
    events,
    categories,
  }
})
