<template>
  <div v-if="admin">
    <div class="container mb-5">
      <div class="d-flex flex-column align-items-center justify-content-center">
        <b-form-input
            v-model="fileName"
            placeholder="File Name"
            class="model-input mb-3"
        />
        <b-form-file
            v-model="trainingFile"
            :state="Boolean(trainingFile)"
            placeholder="Upload training file here"
            drop-placeholder="Drop training file here"
            class="model-input mb-3"
        />
        <b-form-group
            :description="fileName === '' ? 'Please enter a filename' : ''"
        >
          <b-btn
              @click="upload"
              variant="success"
              class="ml-2"
              :disabled="fileName === ''"
          >
            Upload
          </b-btn>
        </b-form-group>
      </div>
      <div v-if="uploadResult">
        File Uploaded
      </div>
    </div>
  </div>
</template>

<script>
import admin from "@/components/shared/mixins/admin";
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage';

export default {
  mixins: [admin],
  name: "EmployeeUploader",
  data() {
    return {
      trainingFile: null,
      uploadError: null,
      uploadResult: null,
      fileName: ''
    }
  },

  methods: {
    upload() {
      if (this.trainingFile) {
        const storage = getStorage();
        const trainingRef = ref(storage, `employeeDocs/${this.fileName}`)
        this.uploadError = null;
        uploadBytes(trainingRef, this.trainingFile)
            .then(snapshot => {
              this.uploadResult = true;
              this.fileName = '';
              this.trainingFile = null;
              this.$emit('updated')
              setTimeout(() => {
                this.uploadResult = false;
              }, 10000)
            })
      }
    }
  }
}
</script>

<style scoped>
.model-input {
  max-width: 600px;
}
</style>
