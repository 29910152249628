<template>
  <div>
    <b-container>
      <PageTitle title="Locations"/>
      <b-alert v-model="addedAlert" variant="success" dismissable>
        Location Added
      </b-alert>
      <NewRestaurant :states="stateNames" v-if="admin"/>
      <div class="d-flex justify-content-center">
        <b-btn
          class="potomac-btn"
          href="https://applebees.com/locations"
          target="_blank"
        >Search By Zip code
        </b-btn>
      </div>

      <PageTitle subtitle="View by State"/>
      <div class="d-flex justify-content-around flex-wrap">
        <b-btn
          variant="link"
          v-for="state in stateNames"
          :key="state"
          @click="() => {filterState === state ? filterState = null : filterState = state}"
          class="mx-1 my-1 potomac-btn"
          :class="{ active: filterState === state }"
        >
          {{ state}}
        </b-btn>
      </div>
      <div
        v-for="state in stateNames"
        v-if="(!filterState || filterState === state) && (states[state] && states[state].length > 0)"
        :key="state"
      >
        <div class="d-flex">
          <PageTitle :subtitle="state"/>
        </div>
        <div class="d-flex flex-wrap">
          <Location
            v-for="restaurant in states[state]"
            :key="restaurant.id"
            :location="restaurant"
          />
        </div>
      </div>


    </b-container>
    <Divider large/>
  </div>

</template>

<script>
  import PageTitle from "@/components/shared/PageTitle";
  import Restaurant from "@/components/Restaurant";
  import admin from "@/components/shared/mixins/admin";
  import NewRestaurant from "@/views/Admin/NewRestaurant";
  import Location from "@/components/Location";
  import Divider from "@/components/shared/Divider";
  import {mapState} from 'vuex';

  export default {
    name: "Restaurants",
    components: {Divider, Location, NewRestaurant, Restaurant, PageTitle},
    mixins: [admin],
    data() {
      return {
        stateNames: [
          'West Virginia',
          'Pennsylvania',
          'Maryland',
          'Virginia',
          'North Carolina'
        ],
        filterState: null,
        addedAlert: false,
      }
    },
    computed: {
      ...mapState('restaurants', ['restaurants', 'states']),
    },
  }
</script>

<style scoped>
  .active {
    border-color: #ffa500;
  }
</style>
