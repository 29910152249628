import {getFirestore, query, onSnapshot, where, collection} from 'firebase/firestore';
import {groupBy} from 'lodash';

export default {
  namespaced: true,
  state() {
    return {
      restaurants: [],
      states: [],
      restsObj: {},
      loading: true,
    }
  },

  mutations: {
    import(state, data) {
      state.restaurants = data;
      state.states = groupBy(data, 'state');
      state.restsObj = {};
      data.forEach(res => {
        state.restsObj[res.id] = res;
      })
      state.loading = false;
    }
  },

  getters: {
    getRestaurant(state, id) {
      return state.restaurants.find(res => res.id === id);
    }
  },

  actions: {
    getRestaurants({state, commit}) {
      const resRef = collection(getFirestore(), 'restaurants');
      const q = query(resRef)
      onSnapshot(q, (result) => {
        let data = [];
        result.forEach(r => {
          data.push({...r.data(), id: r.id})
        })

        commit('import', data);
      })
    }
  }
}
