<template>
  <div>
    <div class="title">
      Event Date
    </div>
    <div class="mb-2" v-if="!showEdit">{{ date | moment('MM/DD/YYYY') }}</div>
    <b-datepicker
        v-else
        v-model="date"
    ></b-datepicker>
  </div>
</template>
<script>
import admin from "@/components/shared/mixins/admin";
import {doc, updateDoc,getFirestore} from "firebase/firestore";

export default {
  name: 'EventDate',
  mixins: [admin],
  props: {
    event: {},
  },
  data() {
    return {
      showEdit: false,
      date: ''
    }
  },
  mounted() {
    this.date = new Date(this.event.date)
  },
  methods: {
    saveDate() {
      updateDoc(doc(getFirestore(), `events/${this.event.id}`), {
        date: Date.parse(this.date) + (new Date(this.date).getTimezoneOffset() * 60000)
      })
          .then(() => {
            this.$notify({
              group: 'main-notifications',
              type: 'success',
              title: 'Date Changed',
              text: 'Event date successfully changed, refresh page to see changes'
            })
            this.showEdit = false;
          })
          .catch((e) => {
            this.$notify({
              group: 'main-notifications',
              type: 'error',
              title: 'Error',
              text: e.message,
            })
            this.showEdit = false;
            console.log(e)
          })
    }
  }
}
</script>
<style scoped>
.title {
  color: #000080;
}

</style>