import {getFirestore, query, onSnapshot, where, collection} from 'firebase/firestore';

export default {
  namespaced: true,
  state() {
    return {
      fundraisers: [],
      byId: {},
      fundraisersLoading: true,
    }
  },

  mutations: {
    import(state, data) {
      state.byId = {};
      state.fundraisers = [];
      data.forEach(fundraiser => {
        state.byId[fundraiser.id] = fundraiser.data();
        state.fundraisers.push({...fundraiser.data(), id: fundraiser.id})
      })
      state.fundraisersLoading = false;
    }
  },

  actions: {
    getFundraisers({state, commit}) {
      const fundRef = collection(getFirestore(), 'fundraisers');
      onSnapshot(query(fundRef, where('visible', '==', true)), result => {
        commit('import', result)
      })
    },

    getAllFundraisers({state, commit}){
      const fundRef = collection(getFirestore(), 'fundraisers');
      onSnapshot(query(fundRef), result => {
        commit('import', result)
      })
    }
  }
}
