<template>
  <div class="d-flex justify-content-center flex-column">
    <div class="title-container mb-5 mx-auto px-3">
      <h2 class="main-title">
        {{ title }}
      </h2>
      <div class="subtitle">{{ subtitle }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: ["title", "subtitle"],
};
</script>

<style scoped>
.main-title {
  color: #000080;
  padding-bottom: 15px;
  letter-spacing: 2.5px;
  padding: 20px;
  max-width: 400px;
  margin: auto;
}

.title-container {
  border-bottom-color: rgba(255, 165, 0, 0.57);
  padding-bottom: 10px;
  border-bottom-width: 4px;
  border-bottom-style: solid;
}

.subtitle {
  color: #ffa500;
  font-size: 25px;
  font-weight: 500;
}
</style>
