<template>
  <b-nav-dropdown right>
    <template #button-content>
      <b-icon-person-fill/>
    </template>
    <b-dropdown-item-btn @click="requestEmployee" v-if="!isEmployee">
      Request Employee Access
    </b-dropdown-item-btn>
    <b-dropdown-item-btn @click="logout">
      Logout
    </b-dropdown-item-btn>
  </b-nav-dropdown>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {
  doc,
  getFirestore,
  updateDoc
} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';

export default {
  name: "User",
  props: ['isEmployee'],
  computed: {
    ...mapState('user', ['user']),

    id(){
      return this.user.uid;
    }
  },
  methods: {
    ...mapActions('user', ['logout']),
    requestEmployee() {
      updateDoc(doc(getFirestore(), `users/${getAuth().currentUser.uid}`), {
        employeeRequested: true
      }).then(() => {
        this.$notify({
          group: 'main-notifications',
          title: "Request Submit",
          text: 'Access request submit as an employee.',
          type: 'success'
        })
      })
    }
  },
}
</script>

<style scoped>

</style>
