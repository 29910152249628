<template>
  <div class="user-container">
    <div class="d-flex align-items-center">
      <b-btn style="text-align: left" variant="link" class="p-0" :to="{name: 'Event', params: {id: event.id}}">
        {{ event.orgInfo.name }}
      </b-btn>
      <div class="ml-auto" sm v-if="!fundraisersLoading">
        {{ fundraisers[event.fundraiser].name }}
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div>
        {{ event.date | moment("M/DD/YYYY") }}
      </div>
      <div class="ml-auto">
        <div style="color: #41a841" v-if="event.approved">
          Approved
        </div>
        <div style="color: #b73c3c" v-else-if="event.rejected">
          Rejected
        </div>
        <div v-else>
          Pending Approval
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div>
        {{restaurant.address}}
      </div>
      <div v-if="admin || operated" class="admin-border d-flex ml-auto">
        <div
            v-if="fundraisers[event.fundraiser].name === 'Flapjack Fundraiser'"
            class="mr-2"
        >
          <div
              v-if="event.deposit"
          >
            Deposit Paid
          </div>
          <b-btn
              v-else
              @click="payDeposit"
              variant="success"
          >Mark Deposit As Paid
          </b-btn>
        </div>
        <b-btn
            v-if="event.approved"
            variant="danger"
            class="ml-auto mr-2"
            @click="reject"
        >
          Reject
        </b-btn>
        <b-btn
            v-else
            variant="success"
            class="ml-auto mr-2"
            @click="approve"
        >
          Approve
        </b-btn>
        <b-btn
            variant="danger"
            v-if="deleteConfirm"
            @click="removeEvent"
        >
          Are you sure?
        </b-btn>
        <b-btn
            variant="info"
            v-else
            @click="confirmRemove"
        >
          <b-icon-trash-fill/>
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import Admin from '@/components/shared/mixins/admin';
import {updateDoc, doc, getFirestore, deleteDoc} from 'firebase/firestore';

export default {
  name: "Event",
  props: ['event'],
  mixins: [Admin],
  data() {
    return {
      deleteConfirm: false
    }
  },
  computed: {
    ...mapState('restaurants', ['restsObj']),
    ...mapState('fundraisers', {fundraisers: 'byId', fundraisersLoading: 'fundraisersLoading'}),
    ...mapState('user', ['userData']),
    restaurant() {
      return this.restsObj[this.event.restaurant];
    },

    operated() {
      return this.userData.restaurant === this.restaurant.id;
    }
  },

  methods: {
    ...mapActions('events', ['getEvents']),
    approve() {
      updateDoc(doc(getFirestore(), `events/${this.event.id}`), {
        approved: true,
        rejected: false
      }).then(() => {
        this.getEvents();
      })
    },

    reject() {
      updateDoc(doc(getFirestore(), `events/${this.event.id}`), {
        approved: false,
        rejected: true
      }).then(() => {
        this.getEvents();
      })
    },

    payDeposit() {
      updateDoc(doc(getFirestore(), `events/${this.event.id}`), {
        deposit: true
      }).then(() => {
        this.getEvents();
      })
    },

    confirmRemove() {
      this.deleteConfirm = true;
      setTimeout(() => {
        this.deleteConfirm = false
      }, 5000)
    },

    removeEvent() {
      deleteDoc(doc(getFirestore(), `events/${this.event.id}`))
          .then(() => {
            this.$notify({
              group: 'main-notifications',
              title: "Event Removed",
              text: `Removed event`,
              type: 'success'
            })
            this.$emit('removed');
          })
    }
  }
}
</script>

<style scoped>

.user-container {
  margin-top: 10px;
  padding: 15px;
  width: 100%;
  max-width: 800px;
  text-align: left;
}

.admin-border {
  padding: 2px;
  border: 1px;
}

</style>
