import {getFirestore, query, onSnapshot, where, collection, addDoc, getDoc} from 'firebase/firestore';

export default {
  namespaced: true,
  state(){
    return {
      categories: [],
      filesAdded: false,
    }
  },

  mutations: {
    import(state, data){
      state.filesAdded = false;
      state.categories = data.map(d => {return {...d, files: []}}) || [];
    },

    addFile(state, data){
      state.categories[data.index].files.push(data.file);
      state.filesAdded = true;
    }
  },

  actions: {
    getCategories({state, commit}) {
      const catRef = collection(getFirestore(), 'categories');
      const q = query(catRef);
      onSnapshot(q, result => {
        let data = [];
        result.forEach(r => {
          data.push({...r.data(), id: r.id})
        })
        commit('import', data);
      })
    },

    newCategory({state, dispatch}, name){
      addDoc(collection(getFirestore(), "categories"), {
        name
      }).then(() => {
        dispatch('getCategories');
      }).catch(e => {
        console.log(e)
      })
    }
  }
}