<template>
  <div class="d-flex file-row justify-content-around mt-2 p-2">
    <b-btn variant="link" :href="url" target="_blank">
      {{ fileName }}
    </b-btn>
    <div v-if="admin" class="ml-auto d-flex">
      <CategorySelector class="mr-2" :file="file"/>
      <b-btn
          v-if="!confirm"
          @click="confirmToggle"
      >
        <b-icon-trash/>
      </b-btn>
      <b-btn
          v-else
          @click="remove"
          variant="danger"
      >
        Delete?
      </b-btn>

    </div>
  </div>
</template>

<script>
import {
  getStorage,
  ref,
  getDownloadURL,
  deleteObject
} from 'firebase/storage';
import admin from "@/components/shared/mixins/admin";
import CategorySelector from './CategorySelector';

export default {
  name: "TrainingFile",
  components: {
    CategorySelector
  },
  props: ['file'],
  mixins: [admin],
  computed: {
    fileName() {
      return this.file.fullPath.split('/')[1]
    }
  },
  data() {
    return {
      url: '',
      confirm: false
    }
  },
  mounted() {
    const storage = getStorage();
    const fileRef = ref(storage, this.file);
    getDownloadURL(fileRef)
        .then(url => {
          this.url = url;
        })
  },
  methods: {
    confirmToggle() {
      this.confirm = true;
      setTimeout(() => {
        this.confirm = false;
      }, 3000)
    },
    remove() {
      const storage = getStorage();
      const fileRef = ref(storage, this.file);
      deleteObject(fileRef).then(() => {
        this.$emit('update');
      })
    },
    downloadFile() {

      const storage = getStorage();
      const fileRef = ref(storage, this.file);
      getDownloadURL(fileRef)
          .then((url) => {
            // `url` is the download URL for 'images/stars.jpg'

            // This can be downloaded directly:
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
              const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();

            // Or inserted into an <img> element
            const img = document.getElementById('myimg');
            img.setAttribute('src', url);
          })
          .catch((error) => {
            // Handle any errors
          });
    }
  }
}
</script>

<style scoped>
.file-row {
  width: 100%;
  border-radius: 5px;
}

.file-row:hover {
  background-color: #d5dede;
}
</style>
