<template>
  <b-navbar toggleable="lg" class="potomac-nav text" sticky>

    <b-navbar-brand :to="{ name: 'Home' }">
      <h1>
        <b-img
            :src="require('@/assets/images/logo.png')"
            fluid
            alt="Potomac Family Dining Group Logo"
            class="pb-3 img"
        ></b-img>
      </h1>
    </b-navbar-brand>

    <b-navbar-toggle target="potomac-nav"></b-navbar-toggle>
    <b-collapse id="potomac-nav" is-nav>
      <b-navbar-nav class="nav-border ml-auto">
        <b-nav-item-dropdown class="potomac-nav pr-4" text="About" right>
          <b-dropdown-item :to="{ name: 'Values' }">
            Our Values
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'President' }">
            Message From President
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'Contact' }">
            Contact Us
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item class="pr-4 test" :to="{ name: 'Careers' }">
          Careers
        </b-nav-item>
        <b-nav-item class="pr-4" :to="{ name: 'Locations' }">
          Locations
        </b-nav-item>
        <b-nav-item class="pr-4" :to="{ name: 'Employee' }" v-show="isEmployee">
          Employee
        </b-nav-item>
        <b-nav-item v-if="!admin" class="pr-4" :to="{name: 'Fundraisers'}">
          Fundraisers
        </b-nav-item>
        <b-nav-item v-if="!admin && !showLogin" :to="{name: 'Events'}" class="pr-4">
          Events
        </b-nav-item>
        <Admin/>
        <b-nav-item class="pr-4" :to="{ name: 'Login' }" v-if="showLogin">
          Login
        </b-nav-item>
        <User v-else-if="!admin" isEmployee="isEmployee"/>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import User from "@/components/shared/Navbar/User";
import {mapState} from "vuex";
import Admin from "@/components/shared/Navbar/Admin";
import adminMix from '@/components/shared/mixins/admin';
import _ from 'lodash';
import employee from "@/components/shared/mixins/employee";

export default {
  name: "Navbar",
  components: {Admin, User},
  mixins: [adminMix, employee],
  computed: {
    ...mapState("user", ["user"]),
    showLogin() {
      return _.isEmpty(this.user.currentUser);
    },
  },
};
</script>

<style scoped>
.potomac-nav {
  letter-spacing: 2.5px;
  text-transform: uppercase;
  font-size: 14px;
  background-color: #fffdfa;
}

h1 {
  padding: unset;
  margin: unset;
}

.nav-border {
  border-top: 2px solid #ffa500;
  padding-top: 15px;
}

.img {
  width: 20vw;
  max-width: 150px;
}

</style>
