<template>
  <b-container class="pb-5">
    <PageTitle title="Events"/>
    <NewEvent v-if="admin"/>
    <div class="d-flex justify-content-end align-items-center">
      <div v-if="viewAll" class="mt-2 mr-2">
        <b-form-input
            placeholder="Search org name"
            v-model="orgSearch"
        ></b-form-input>
      </div>
      <b-btn
        variant="success"
        v-if="admin"
        @click="viewPending = !viewPending"
        class="mt-2 mr-2"
        >
        <span v-if="viewPending">Clear Filter</span>
        <span v-else>{{notApproved.length}} Pending</span>
      </b-btn>
      <b-btn
          variant="success"
          v-if="admin"
          @click="exportEvents"
          class="mt-2"
      >
        Export
      </b-btn>
      <b-btn
        variant="info"
        v-if="admin"
        @click="viewAll = !viewAll"
        class="mt-2 mx-2"
      >
        {{ viewAll ? 'View Less' : 'View All'}}
      </b-btn>

    </div>
    <div class="d-flex flex-column align-items-center">

      <Loading v-if="loadingEvents"/>
      <div v-else class="d-flex flex-column align-items-center">
        <EventTable
          v-if="viewAll"
          :events="eventList"
        />
        <Event
            v-else
            v-for="event in eventList"
            :key="event.id"
            :event="event"
            @removed="getEvents"
        />

      </div>

    </div>
  </b-container>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle";
import {onAuthStateChanged, getAuth} from 'firebase/auth';
import Event from "@/components/Event";
import {groupBy} from 'lodash';
import {mapState, mapActions} from 'vuex';
import Loading from "@/components/shared/Loading";
import admin from "@/components/shared/mixins/admin";
import NewEvent from "@/components/admin/NewEvent";
import EventTable from "@/components/admin/EventTable";

export default {
  name: "Events",
  mixins: [admin],
  components: {EventTable, NewEvent, Event, PageTitle, Loading},
  data(){
    return {
      viewPending: false,
      viewAll: false,
      orgSearch: ""
    }
  },

  computed: {
    ...mapState('restaurants', ['restsObj', 'loading']),
    ...mapState('events', {loadingEvents: 'loading', events: 'events', allEvents: 'allEvents'}),
    ...mapState('user', ['user']),
    ...mapState('fundraisers', {fundraiserById: 'byId'}),
    approved() {
      return this.events.filter(x => x.approved);
    },

    eventList() {
      let events = this.allEvents;
      if (!this.viewAll){
        if (this.viewPending){
          events = this.notApproved
        } else {
          events = this.dateSorted
        }
      }
      if (this.orgSearch.length > 2 && this.viewAll){
        events = events.filter(event => event.orgInfo.name.toLowerCase().includes(this.orgSearch.toLowerCase()))
      }
      return events;
    },


    dateSorted(){
      return this.events.sort((a, b) => {
        if (a.date === b.date){
          return 0;
        } else {
          return a.date > b.date ? 1 : -1
        }
      })
    },

    notApproved() {
      return this.events.filter(x => !x.approved && x.approved !== false);
    },

    byRestaurant() {
      return groupBy(this.events, (e) => e.restaurant)
    }
  },

  methods: {
    ...mapActions('events', ['getEvents']),

    exportEvents() {
      // Potomac want export of Fundraiser Type, Org Name, Event Date, Restaurant Name
      let reducedEvents = this.events;
      reducedEvents.unshift();
      reducedEvents = reducedEvents
          .map(event => {
            return {
              "Fundraiser Type": this.fundraiserById[event.fundraiser].name,
              "Org Name": event.orgInfo.name,
              "Date": new Date(event.date),
              "Restaurant": this.restsObj[event.restaurant].city
            }
          })
      let csv = [Object.keys(reducedEvents[0])]
          .concat(reducedEvents)
          .map(row => {
            return Object.values(row).toString()
          })
          .join('\n')


      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv))
      element.setAttribute('download', `Events.csv`)

      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();

      document.body.removeChild(element);
    }
  },

  mounted() {
    onAuthStateChanged(getAuth(), user => {
      if (user.uid) {
        this.getEvents(this.user.restaurant);
      }
    })
  }
}
</script>

<style scoped>

</style>
