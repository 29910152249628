import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";
import Login from "../views/Login.vue";
import President from "../views/President.vue";
import Values from "../views/Values.vue";
import Employee from "../views/Employee.vue";
import Careers from "../views/Careers.vue";
import Coaches from "../views/Coaches.vue";
import Fundraiser from "@/views/Fundraiser";
import Restaurants from "@/views/Restaurants";
import Events from "@/views/Events";
import Event from '@/views/Event';

import {getAuth} from 'firebase/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },

  {
    path: "/locations",
    name: "Locations",
    component: Restaurants,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
    beforeEnter: (to, from, next) => {
      if (!getAuth().currentUser) {
        next({name: 'Login'})
      }
      next();
    }
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: Event,
    props: true,
    beforeEnter: (to, from, next) => {
      if (!getAuth().currentUser) {
        next({name: 'Login'})
      }
      next();
    }
  },
  {
    path: "/president",
    name: "President",
    component: President,
  },
  {
    path: "/values",
    name: "Values",
    component: Values,
  },
  {
    path: "/employee",
    name: "Employee",
    component: Employee,
  },
  {
    path: "/careers",
    name: "Careers",
    component: Careers,
  },
  {
    path: "/coaches",
    name: "Coaches",
    component: Coaches,
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import( "@/views/Admin/Users"),

  },
  {
    path: "/fundraisers",
    name: "Fundraisers",
    component: () => import ("@/views/Fundraisers"),
  },
  {
    path: "/fundraiser/:id",
    name: "Fundraiser",
    component: Fundraiser,
    props: true
  },
  {
    path: "/org_types",
    name: 'OrgTypes',
    component: () => import ("@/views/Admin/OrgTypes"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
