export default {
  props: ['event', 'restaurant'],
  data() {
    return {
      generating: false,
    }
  },

  methods: {
    startedGenerating() {
      this.generating = true;
    },

    generated() {
      this.generating = false;
    },

    generate() {
      this.$refs.pdf.generatePdf();
    }
  }
}
