<template>
  <b-button
    target="_blank"
    type="button"
    variant="link"
    class="btn btn-outline-primary buttons potomac-btn"
    href="https://apply.jobappnetwork.com/potomac-family-dining"
  >
    APPLY ONLINE
  </b-button>
</template>

<script>
export default {};
</script>

<style scoped>
.buttons {
  text-decoration: unset !important;
}
</style>
