<template>
  <div>
    <b-container class="bv-example-row">
      <div style="padding-bottom: 70px">
        <PageTitle title="Careers" />
        <div class="careers-text">
          <div>
            We place a high priority on the satisfaction of our guests and
            employees. As an organization, we work to improve their day-to-day
            experiences in our restaurants through giving excellent service,
            seeking expansion and innovation, and consistently providing reward
            and recognition.
          </div>

          <div class="pt-5">
            We aim to promote internally as often as possible, giving our
            devoted team members the first opportunity to level up into salaried
            manager or support staff positions before anyone else. In 2021, we
            promoted a total of five salaried managers to be General Managers,
            in addition to inviting a former General Manager to become an Area
            Director and bringing on a former Bartender to be our Human
            Resources Coordinator. We love our people, and we are invested in
            helping them pursue the height of their potential and facilitating
            in their career development.
          </div>
          <div class="pt-5 pb-5">
            If you are looking for a great opportunity to be a part of an
            organization that recognizes entrepreneurial spirit while building
            upon the strongly established Applebee's brand, then Potomac Family
            Dining is the place for you!
          </div>
        </div>
        <ApplyButton />
        <divider class="my-2" />
        <AlternatingCareer class="alternating-career" />
      </div>
    </b-container>
    <Divider large />
  </div>
</template>

<script>
import AlternatingCareer from "@/components/AlternatingCareer/AlternatingCareer";
import Divider from "@/components/shared/Divider";
import PageTitle from "@/components/shared/PageTitle";
import ApplyButton from "../components/shared/ApplyButton.vue";
export default {
  components: { PageTitle, Divider, AlternatingCareer, ApplyButton },
  data() {
    return {
      offers: [
        "Promotions from Within",
        "Dining Privelages",
        "Rapid Growing Company",
        "Personal and Career Growth",
        "Competitive Salary",
        "Paid Vacation",
        "And More!",
      ],
    };
  },
};
</script>

<style>
.careers-text {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  text-align: left;
}

.alternating-career {
  padding-top: 50px;
}

.offers {
  text-align: left;
  padding: 15px;
}
</style>
