<template>
  <div>
    <b-btn variant="link" class="p-0" :disabled="generating" @click="generate">
      Download Flyer
      <span v-if="generating"><Loading/></span>
    </b-btn>
    <vue-html2pdf
        :show-layout="false"
        filename="FlapJack Fundraiser"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        :paginate-elements-by-height="1400"
        @hasStartedGeneration="startedGenerating()"
        @hasGenerated="generated()"
        ref="pdf"
    >
      <section slot="pdf-content">
        <div class="header">
          <b-img
              :src="require('../../assets/images/flapjack-flyer-logo.png')"
              class="header-image"
          />
          <div class="header-content">
            you're invited to an applebee's flapjack fundraiser in support of
            <div class="larger-content">
              {{ event.orgInfo.name }}
            </div>
          </div>
        </div>
        <div class="content center">
          <div class="title-line">
            {{ event.date | moment("MM/DD/YYYY") }}
          </div>
          <div class="desc-line">
            At Applebee's, Located at
          </div>
          <div class="desc-line">
            {{ restaurant.address }} {{ restaurant.city }}, {{ restaurant.state }}
          </div>

          <div class="title-line">
            Ticket Price: ${{ event.ticketPrice }}
          </div>
          <div class="desc-line">
            Each ticket holder will receive a breakfast sampler that includes 3 pancakes, scrambled eggs, sausage links,
            & your choice of refreshment.
          </div>
          <div class="title-line">
            Buy your ticket now!
          </div>
          <div class="desc-line">
            Contact {{ event.orgInfo.name }}
          </div>
          <div class="desc-line">
            {{ event.orgInfo.phone }} | {{ event.orgInfo.email }}
          </div>
        </div>
        <div class="footer p-3">
          <b-row>
            <b-col cols="7">
              <div>
                applebees.com &copy; | 2022 Applebee's Restaurants LLC
              </div>
              <div class="left mt-5">
                Donation percentage includes tax and tip. Valid at participating restaurants. Must present flyer in
                order for organization to receive credit for purchase. Flyers are not to be distributed in the
                restaurant or within the perimeter of the parking lot. Not valid with other discounts or promotions.
                Applebee's franchises reserve the right to accept or reject requests from organizations at their own
                discretion. Additional restrictions may apply. See restaurant for details.
              </div>
            </b-col>
            <b-col>
              <b-img
                  :src="require('../../assets/images/applebees-flyer-logo.png')"
                  class="logo"
              />
            </b-col>
          </b-row>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import pdf from "@/components/shared/mixins/pdf";
import Loading from "@/components/shared/Loading";

export default {
  components: Loading,
  name: "FlapJackFundraiser",
  mixins: [pdf],
}
</script>

<style scoped>
.header {
  background: #a81e2d;
  height: 450px;
  color: white;
  position: relative;
}

.header-content {
  position: absolute;
  top: 310px;
  left: 10px;
  width: 58%;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.larger-content {
  font-size: 28px;
}

.logo {
  padding-top: 25px;
  height: 100%;
  width: 100%;
}

.header-image {
  width: 100%;
  height: auto;
}

.content {
  height: 450px;
}

.footer {
  height: 222px;
  background: #a81e2d;
  color: white;
  size: 8px;
  line-height: 14px;
}

.title-line {
  font-weight: 900;
  font-size: 36px;
  padding-top: 30px;
}

.desc-line {
  text-transform: uppercase;
  font-size: 20px;
}
</style>
