<template>
  <b-container>
    <PageTitle :title="fundraiser.name"/>
    <div v-if="!agreed">
      <DinetoDonateAgreement v-if="fundraiser.name === 'Dining To Donate'"/>
      <FlapjackAgreement v-else-if="fundraiser.name === 'Flapjack Fundraiser'"/>
      <TogetherWeCare v-else/>

      <b-btn
          variant="success"
          class=" mt-5 mb-5"
          @click="() => {agreed = !agreed}"
      >
        I Agree
      </b-btn>
    </div>
    <div v-else>
      <div class="description">
        {{ fundraiser.description }}
      </div>
      <div class="d-flex justify-content-center mt-5" v-if="!showForm">
        <b-btn @click="showForm = !showForm" class="potomac-btn">Apply Again</b-btn>
      </div>
      <div v-if="loggedIn">
        <div class="mt-5">
          <FundraiserApplicationForm
              v-if="!loading && showForm"
              :fundraiser="fundraiser"
              :agreed="!!agreed"
              :id="id"
              :approved="!!approved"
              @applied="() => {showForm = false}"
              @cancelApp="() => {showForm = !showForm}"
          />
        </div>
      </div>
      <div v-else>
        <b-btn class="potomac-btn my-5" :to="{name: 'Login'}">
          Please login to apply
        </b-btn>
      </div>
    </div>
  </b-container>


</template>

<script>
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {doc, getDoc, getFirestore} from 'firebase/firestore';
import PageTitle from "@/components/shared/PageTitle";
import FundraiserApplicationForm from "@/components/FundraiserApplicationForm";
import DinetoDonateAgreement from "@/views/DinetoDonateAgreement";
import FlapjackAgreement from "@/views/FlapjackAgreement";
import TogetherWeCare from "@/views/TogetherWeCare";

export default {
  name: "Fundraiser",
  components: {TogetherWeCare, DinetoDonateAgreement, FundraiserApplicationForm, PageTitle, FlapjackAgreement},
  props: {
    id: {},
  },

  data() {
    return {
      fundraiser: {},
      showForm: true,
      loggedIn: false,
      showSuccessAlert: false,
      agreed: false,
      approved: false,
      loading: true,
    }
  },

  methods: {
    showSuccess() {
      this.showForm = false;
      this.showSuccessAlert = true;
    }
  },

  mounted() {
    onAuthStateChanged(getAuth(), (user) => {
      getDoc(doc(getFirestore(), `/fundraisers/${this.id}`)).then(data => {
        this.fundraiser = data.data()
        this.loading = false;
      })
      if (user) {
        this.loggedIn = true;
        this.agreed = this.$route?.query?.agreed
        this.approved = this.$route.query?.approved
      }
    })
  },

  watch: {
    showForm(){
      window.scrollTo(0,0);
    },

    agreed(){
      window.scrollTo(0,0);
    }
  }
}
</script>

<style scoped>
.description {
  max-width: 600px;
  margin: auto;
  text-align: left;
}

</style>
