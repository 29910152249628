<template>
<div>
  <b-table striped hover :items="items" :fields="fields">
    <template #cell(id)="row">
      <b-button size="sm" :to="`/event/${row.value}`" class="mr-2">
        Visit
      </b-button>

    </template>
  </b-table>
</div>
</template>

<script>
import {mapState, mapGetters} from "vuex";

export default {
  props: ['events'],
  computed: {
    ...mapState('restaurants', ['restaurants']),
    ...mapState('fundraisers', ['fundraisers']),
    items(){
      return this.events.map(event => {
        return {
          ...event,
          ...event.orgInfo
        }
      })
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'date',
          sortable: true,
          label: "Date",
          formatter: ((value) => new Date(value).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) )
        },
        {
          key: 'name',
          sortable: true,
          label: 'Org Info'
        },
        {
          key: 'approved',
          sortable: true,
          label: 'Approved',
          formatter: (value => value ? 'Yes' : 'No')
        },
        {
          key: 'restaurant',
          sortable: true,
          label: 'Restaurant',
          formatter: (value => this.restaurants.find(restaurant => restaurant.id === value)?.city)
        },
        {
          key: 'fundraiser',
          sortable: true,
          label: 'Fundraiser Type',
          formatter: (value => this.fundraisers.find(fundraiser => fundraiser.id === value)?.name)
        },
        {
          key: 'id',
          label: 'Link'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">

</style>