import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
// import VeeValidate from 'vee-validate'
import Notifications from 'vue-notification';
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import 'bootstrap/scss/bootstrap.scss';
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

Vue.use(Notifications);
// Vue.use(VeeValidate, {
//   // This is the default
//   inject: true,
//   // Important to name this something other than 'fields'
//   fieldsBagName: 'veeFields',
//   // This is not required but avoids possible naming conflicts
//   errorBagName: 'veeErrors'
// });
Vue.config.productionTip = false;


import firebaseConf from "../.firebase.conf.js";
// import firebaseConfDev from '../.dev.firebase.conf.js';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import store from "./store";

Vue.use(require("vue-moment"));

import VueHtml2pdf from "vue-html2pdf";

Vue.use(VueHtml2pdf);

const firebaseApp = initializeApp(firebaseConf );
const analytics = getAnalytics(firebaseApp);

import VueSkipTo from 'vue-skip-to';
Vue.use(VueSkipTo);

import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(firebaseConf.siteKey),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
