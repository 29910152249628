import Vue from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import {
  BButton,

  BNavbar,
  BNav,
  BNavItemDropdown,
  BDropdownItem,
  BNavItem,
  BDropdownItemButton,
  BIconPersonFill,
  BLink,
  BIconLinkedin,
  BIconTwitter,
  BIconInstagram,
  BIconFacebook,
  BImg,
  BContainer,
  BRow,
  BCol,
  BIconCheck,
  VBToggle,
  BIconCaretRight,
  BCollapse,
  BIconBarChartFill,
  BIconPeaceFill,
  BIconPeopleFill,
  BIconHouseDoorFill,
  BNavbarNav,
  BNavbarBrand,
  BNavbarToggle,
  BFormInput,
  BForm,
  BFormGroup,
  BFormTextarea,
  BModal,
  BIconMap,
  BIconPencil,
  BIconTrash,
  BAlert,
  BFormSelect,
  BFormFile,
  BTab,
  BTabs,
  BIconTrashFill,
  BIconPlus, BCard, BCardText, BFormDatepicker, BInputGroup, BSpinner, BTable, BDropdown,
} from "bootstrap-vue";


Vue.component('b-btn', BButton)

Vue.component('b-nav-item', BNavItem)
Vue.component('b-nav-dropdown', BNavItemDropdown)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-nav', BNav)
Vue.component('b-navbar', BNavbar)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-dropdown-item-btn', BDropdownItemButton)
Vue.component('b-icon-person-fill', BIconPersonFill);
Vue.component('b-link', BLink);
Vue.component('b-icon-linkedin', BIconLinkedin)
Vue.component('b-icon-twitter', BIconTwitter)
Vue.component('b-icon-instagram', BIconInstagram)
Vue.component('b-icon-facebook', BIconFacebook);
Vue.component('b-img', BImg);
Vue.component('b-container', BContainer);
Vue.component('b-row', BRow);
Vue.component('b-col', BCol);
Vue.component('b-button', BButton);
Vue.component('b-icon-check', BIconCheck);
Vue.directive('b-toggle', VBToggle)
Vue.component('b-icon-caret-right', BIconCaretRight);
Vue.component('b-collapse', BCollapse);
Vue.component('b-icon-bar-chart-fill', BIconBarChartFill)
Vue.component('b-icon-people-fill', BIconPeopleFill);
Vue.component('b-icon-house-door-fill', BIconHouseDoorFill);
Vue.component('b-navbar-nav', BNavbarNav)
Vue.component('b-navbar-brand', BNavbarBrand)
Vue.component('b-navbar-toggle', BNavbarToggle)
Vue.component('b-nav-item-dropdown', BNavItemDropdown)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form', BForm)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-text-area', BFormTextarea)
Vue.component('b-modal', BModal);
Vue.component('b-icon-map', BIconMap);
Vue.component('b-icon-pencil', BIconPencil);
Vue.component('b-icon-trash', BIconTrash);
Vue.component('b-alert', BAlert)
Vue.component('b-form-select', BFormSelect);
Vue.component('b-form-file', BFormFile);
Vue.component('b-tabs', BTabs);
Vue.component('b-tab', BTab);
Vue.component('b-icon-trash-fill', BIconTrashFill)
Vue.component('b-icon-plus', BIconPlus);
Vue.component('b-card', BCard);
Vue.component('b-card-text', BCardText)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-datepicker', BFormDatepicker);
Vue.component('b-spinner', BSpinner);
Vue.component('b-table', BTable);

