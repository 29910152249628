<template>
  <div class="m-2 d-flex flex-column">
    <div class="location p-3 d-flex">
      <div class="mr-4">
        <div class="city-name">{{ location.city }}</div>
        <div>
          {{ location.phone }}
        </div>
        <div>{{ location.address }}</div>
        <div>{{ location.city }}</div>
        <div class="pb-3">{{ location.state }}</div>
      </div>
      <div
          class="ml-auto d-flex align-items-center flex-column justify-content-center"
      >
        <b-btn
            size="sm"
            :href="location.facebookLink"
            target="_blank"
            class="icon-button potomac-btn"
        >
          <b-icon-facebook class="icon"></b-icon-facebook>
        </b-btn>
        <b-btn
            size="sm"
            :href="location.googleMapsLink"
            target="_blank"
            class="icon-button potomac-btn"
        >
          <b-icon-map class="icon"></b-icon-map>
        </b-btn>
      </div>
    </div>
    <div v-if="admin" class="admin-border p-3 d-flex">
      <b-btn size="sm" variant="danger" @click="hide" v-if="location.visible">
        Hide From Events
      </b-btn>
      <b-btn size="sm" variant="success" @click="show" v-else>
        Show For Events
      </b-btn>
      <b-btn size="sm" variant="success" class="ml-auto" @click="toggleEdit">
        <b-icon-pencil/>
      </b-btn>
      <div class="ml-2" v-if="confirm">
        <b-btn size="sm" class="mb-2" @click="removeRestaurant" variant="danger">
          Are you sure? (Yes)
        </b-btn>
        <b-progress height="5px" :value="timer" :max="5" variant="danger"/>
      </div>
      <b-btn size="sm" variant="danger" class="ml-2" @click="setConfirm" v-else>
        <b-icon-trash/>
      </b-btn>
    </div>
    <b-modal
        v-model="editing"
        :title="`Edit ${location.city}`"
    >
      <b-form @submit="onSubmit">
        <b-form-group
            label="Street Address"
            label-for="street-address"
        >
          <b-form-input
              id="street-address"
              v-model="location.address"
              required
          />
        </b-form-group>

        <b-form-group
            label="City"
            label-for="city"
        >
          <b-form-input
              id="city"
              v-model="form.city"
              required
          />
        </b-form-group>

        <b-form-group
            label="Phone"
            label-for="phone"
        >
          <b-form-input
              id="phone"
              v-model="form.phone"
              required
          />
        </b-form-group>

        <b-form-group
            label="Facebook Link"
            label-for="facebook"

        >
          <b-form-input
              placeholder="https://"
              id="facebook"
              v-model="form.facebookLink"
              required
          />
        </b-form-group>

        <b-form-group
            label="Google Maps Link"
            label-for="googleMaps"
            placeholder="https://"
        >
          <b-form-input
              placeholder="https://"
              id="googleMaps"
              v-model="form.googleMapsLink"
              required
          />
        </b-form-group>

        <div class="d-flex my-4">
          <b-btn class="ml-auto mr-3" type="submit" variant="success">
            Save
          </b-btn>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import admin from '@/components/shared/mixins/admin';
import {deleteDoc, doc, getFirestore, updateDoc} from 'firebase/firestore';

export default {
  name: "Location",
  props: ["location", "state"],
  mixins: [admin],
  data() {
    return {
      timer: 0,
      confirm: false,
      editing: false,
      form: {},
    }
  },
  methods: {
    setConfirm() {
      this.confirm = true;
      setTimeout(this.incrementTimer, 250);
    },

    removeRestaurant() {
      deleteDoc(doc(getFirestore(), `restaurants/${this.location.id}`))
    },

    incrementTimer() {
      this.timer = this.timer + 0.25;
      if (this.timer > 5.5) {
        this.confirm = false;
        this.timer = 0;
      } else {
        setTimeout(this.incrementTimer, 250);
      }
    },

    toggleEdit() {
      this.form = {...this.location}
      delete this.form.id
      this.editing = !this.editing
    },

    onSubmit(event) {
      event.preventDefault();
      updateDoc(doc(getFirestore(), `restaurants/${this.location.id}`), {
        ...this.form
      }).then(() => {
        this.toggleEdit();
      })
    },

    hide() {
      updateDoc(doc(getFirestore(), `restaurants/${this.location.id}`), {
        visible: false
      })
    },

    show() {
      updateDoc(doc(getFirestore(), `restaurants/${this.location.id}`), {
        visible: true
      })
    },
  }
};
</script>

<style scoped>
.icon-button {
  border: none;
}

.icon-button:hover {
  border: none !important;
}

.icon-button:hover > .icon {
  color: #ffa500;
}

.icon {
  color: #007bff;
}

.city-name {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

.location {
  border: #000080 solid 1px;
  width: 350px;
  text-align: left;
  background-color: #1d18640c;
}


</style>
