<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="8" class="mx-auto form">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <div class="form-titles">Personal Information</div>

            <b-row>
              <b-col>
                <b-form-group
                  label="First name"
                  id="firstName"
                  type="text"
                  label-for="firstName"
                >
                  <b-form-input
                    id="firstName"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="form.firstName"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Last name"
                  id="lastName"
                  type="text"
                  label-for="lastName"
                >
                  <b-form-input
                    id="lastName"
                    v-model="form.lastName"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group
              id="email"
              label="Email address"
              type="email"
              label-for="email"
              description="We'll never share your email with anyone else."
            >
              <b-form-input
                id="email"
                v-model="form.email"
                type="email"
                required
              ></b-form-input>
            </b-form-group>

            <b-row>
              <b-col>
                <b-form-group
                  label="Primary phone number"
                  id="primary"
                  type="number"
                  label-for="primary"
                >
                  <b-form-input
                    id="primary"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="form.primary"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Alternate phone number"
                  id="alternate"
                  type="number"
                  label-for="alternate"
                >
                  <b-form-input
                    id="alternate"
                    v-model="form.alternate"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group
              id="comments"
              type="text"
              label="Comments"
              label-for="comments"
            >
              <b-form-text-area
                id="comments"
                v-model="form.comments"
                placeholder="Tell us about your experience"
                rows="4"
                required
              ></b-form-text-area>
            </b-form-group>

            <b-form-group
              id="address"
              label="Address"
              type="address"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="form.address"
                type="address"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="suite"
              label="Suite, Apt, etc."
              type="suite"
              label-for="suite"
            >
              <b-form-input
                id="suite"
                v-model="form.suite"
                type="suite"
              ></b-form-input>
            </b-form-group>

            <b-row>
              <b-col>
                <b-form-group
                  label="City"
                  id="city"
                  type="text"
                  label-for="city"
                >
                  <b-form-input
                    id="city"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="form.city"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="State"
                  id="personalState"
                  type="text"
                  label-for="personalState"
                >
                  <b-form-input
                    id="personalState"
                    v-model="form.personalState"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Zip"
                  id="zip"
                  type="number"
                  label-for="zip"
                  description="Must be a number"
                >
                  <b-form-input
                    id="zip"
                    v-model="form.zip"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="form-titles">Restaurant Information</div>

            <b-form-group
              id="state"
              label-for="state"
              label="State"
              type="select"
            >
              <b-form-select
                id="state"
                v-model="form.state"
                :options="stateOptions"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="location"
              label-for="location"
              label="Location"
              type="select"
            >
              <b-form-select
                id="location"
                v-model="form.location"
                :options="addressOptions"
                required
              ></b-form-select>
            </b-form-group>

            <div class="form-titles">Visit Information</div>
            <b-form-group
              id="type"
              label-for="type"
              label="Visit type"
              type="select"
            >
              <b-form-select
                id="type"
                v-model="form.type"
                :options="type"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group
              id="party"
              label="How many people were in your party?"
              type="party"
              label-for="party"
              description="Must be a number"
            >
              <b-form-input
                id="party"
                v-model="form.party"
                type="number"
                description="Must be a number"
                required
              ></b-form-input>
            </b-form-group>

            <b-row>
              <b-col>
                <b-form-group
                  label="Server's Name"
                  label-for="server"
                  type="select"
                  id="server"
                >
                  <b-form-input
                    id="server"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="form.server"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="check"
                  label="Check Number"
                  type="check"
                  label-for="check"
                  description="Must be a number"
                >
                  <b-form-input
                    id="check"
                    type="number"
                    v-model="form.check"
                    description="Must be a number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group label="Date">
                  <b-form-input type="date" v-model="form.date"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Time">
                  <b-form-input type="time" v-model="form.time"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="pb-5 d-flex justify-content-end">
              <b-button type="submit" variant="success" class="mr-3"
                >Submit</b-button
              >
              <b-button type="reset" variant="danger">Reset</b-button>
            </div>
          </b-form>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {getFunctions, httpsCallable} from "firebase/functions";
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState('restaurants', ['restaurants']),
    stateOptions(){
      return [... new Set(this.restaurants.map(rest => rest.state))]
    },
    addressOptions(){
      if (this.form.state){
        return this.restaurants
            .filter(r => r.state === this.form.state)
            .map(r => r.address)
      }
      return this.restaurants.map(r => r.address)
    }
  },

  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        primary: "",
        alternate: "",
        comments: "",
        address: "",
        suite: "",
        city: "",
        personalState: "",
        zip: "",
        state: null,
        location: null,
        type: null,
        party: "",
        server: "",
        check: "",
        date: "",
        time: "",
      },
      type: [
        { text: "Select One", value: null },
        "Dine In",
        "To Go",
        "Delivery",
        "Catering",
      ],
      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      httpsCallable(getFunctions(), "contactFormMigration")(this.form).then(result => {
        this.$notify({
          group: 'main-notifications',
          title: "Comments Submited",
          text: 'Thank you for your feedback!',
          type: 'success'
        })
      })
          .catch(e => {
            console.log(e)
          })
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.firstName = "";
      this.form.lastName = "";
      this.form.email = "";
      this.form.primary = "";
      this.form.alternate = "";
      this.form.comments = "";
      this.form.address = "";
      this.form.suite = "";
      this.form.city = "";
      this.form.personalState = "";
      this.form.zip = "";
      this.form.state = null;
      this.form.location = null;
      this.form.type = null;
      this.form.party = "";
      this.form.server = "";
      this.form.check = "";
      this.form.date = "";
      this.form.time = "";

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style scoped>
.form-titles {
  font-size: 20px;
  padding-bottom: 15px;
  padding-top: 10px;
  font-weight: bold;
}

.form {
  text-align: left;
}

.time {
  border: 1px solid black;
  padding: 20px;
}

.date {
  border: 1px solid black;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
