<template>
  <div>
    <b-nav-dropdown v-if="admin" text="Admin" right class="pr-4">
      <b-dropdown-item
        v-for="page in pages"
        :key="`page-${page}`"
        :to="{ name: page}"
      >
        {{ page }}
      </b-dropdown-item>
      <b-dropdown-item
        @click="logout"
      >
        Logout
      </b-dropdown-item>
    </b-nav-dropdown>
    <!--    <b-btn v-if="rootAdmin" @click="toggleEmulate">-->
    <!--      View As <span v-if="admin">User</span> <span v-else>Admin</span>-->
    <!--    </b-btn>-->

  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex';
  import admin from '@/components/shared/mixins/admin';

export default {
  name: "Admin",
  data() {
    return {
      pages: [
        'Users',
        'Fundraisers',
        'Locations',
        'Events',
        'Employee'
      ]
    }
  },

  computed: {
    ...mapState('users', ['user']),
  },
  mixins: [admin],
  methods: {
    ...mapActions('user', ['logout']),
  }
}
</script>

<style scoped>

</style>
