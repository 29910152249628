<template>
  <div class="agreement">
    <p>
      Please carefully review the following terms:
    </p>
    <p>
      All organizations will be charged sales tax, unless they can provide valid tax exemption documentation. Also, for
      every ticket redeemed on the day of the event, $4 will be given back to Applebee's.
    </p>
    <p>
      The reservation you make on <a href="https://potomacdiningserves.com/">www.PotomacDiningServes.com</a> is invalid
      until you provide the required
      non-refundable
      $100 deposit to the Applebee's manager within 5 days of your initial reservation. The manager will then approve
      your date and you will receive an email that reads "[NAME], Your [EVENT DATE] Applebee's Flapjack request has been
      accepted."
    </p>
    <p>
      Responsibility of Applebee's:
    </p>
    <ul>
      <li>
        Provide facility (location), kitchen and management staff.
      </li>
      <li>
        Provide plates, silverware and glasses.
      </li>
      <li>
        Order food/ingredients and prepare food.
      </li>
      <li>
        Make the event turn-key for organizations by supplying tickets and flyers for groups to copy and advertise.
      </li>
    </ul>
    <p>
      Responsibility of Organization:
    </p>
    <ul>
      <li>
        Advertise and promote the event utilizing tickets and flyer provided by Applebee's.
      </li>
      <li>
        Provide volunteers to greet, seat, serve and clean up.
      </li>
      <li>
        Print and sell tickets (recommended selling price is $10 - 12 per ticket)
      </li>
    </ul>

    <p>
      Applebee's has the right to deny any Flapjack Fundraisers. Should your organization not meet one of our approved
      categories your request to host a Flapjack Fundraiser may be denied.
    </p>
  </div>
</template>

<script>
export default {
  name: "FlapjackAgreement"
}
</script>

<style scoped>
.agreement {
  text-align: left;
  max-width: 600px;
  margin: auto;
}
</style>