<template>
  <div class="d-flex flex-column align-items-center">
    <PageTitle title="About Us"/>

    <div>
      <p>
        Potomac Family Dining was founded in late 2010 by Mr. Timothy George
        with the intended purpose of acquiring 30 Applebee’s in Northern
        Virginia, Southern Maryland, and parts of Pennsylvania and West Virginia
        areas.
      </p>
      <p>
        Mr. George’s vision was to create a company that would make a commitment
        to becoming a major franchisee and leader in the Applebee’s brand. He
        felt that being part of the largest national brand in the casual dining
        segment would allow him to leverage the advantages such a national
        relationship could provide. He assumed the responsibility of operating
        America’s Neighborhood restaurant, noting that Potomac Family Dining
        Group would take an active role in the local communities that it serves
        to earn the trust and love of guests in every town.
      </p>
      <p>
        True to Mr. George’s vision, Potomac Family Dining acquired 39
        additional Applebee’s in Southern Virginia and part of Northern North
        Carolina in October 2012. After this second acquisition, the company
        became the sixth largest franchisee in the Applebee’s system.
      </p>
      <div class="pb-5 pt-4">
        <b-img
            :src="require('@/assets/images/Lucy.jpg')"
            width="400"
            fluid
            alt="Potomac Dining Logo"
            class="about-image"
        >
        </b-img>
      </div>
      <p>
        Today, Potomac Family Dining Group operates 49 restaurants in Virginia,
        Maryland, West Virginia, Pennsylvania, and North Carolina. Under the
        leadership of Mr. Timothy George and CEO/President Dennis Benson,
        Potomac Family Dining Group has amassed a record of operational
        excellence in the last several years.
      </p>
      <p>
        In November 2021, Potomac displayed the height of its’ potential success
        when our organization’s Senior Director of Human Resources, Shannon
        Long, was awarded the National Training and Development Partner of the
        Year by the Applebee’s brand.
      </p>
      <p class="pb-4">
        We believe in our organization and its’ people, and we are looking
        forward to a very bright future.
      </p>
    </div>

    <div>
      <b-img
          :src="require(`@/assets/images/logo-diamond.png`)"
          alt="better together logo"
          class="my-5"
      ></b-img>

      <ul>
        <li><strong style="color: red">PASSION</strong> for People</li>
        <li>
          <strong style="color: orange">FUN</strong> with Respect and Integrity
        </li>
        <li>
          <strong style="color: blue">DELIVER</strong> Operational Excellence
        </li>
        <li><strong style="color: green">GROWTH</strong> Through Teamwork</li>
      </ul>
    </div>

    <Divider large/>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle";
import Divider from "@/components/shared/Divider";

export default {
  components: {Divider, PageTitle},
};
</script>

<style scoped>
.about-image {
  box-shadow: 5px 5px 10px 5px rgba(54, 49, 49, 0.13);
}

p {
  max-width: 560px;
  text-align: left;
}

ul {
  text-align: left;
}

li {
  font-size: 27px;
  list-style: none;
}
</style>
