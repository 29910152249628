<template>
  <div>
    <div class="title">Org Name</div>
    <div class="mb-2">{{ event.orgInfo.name }}</div>
  </div>
</template>
<script>
export default {
  name: 'EventOrg',
  props: {
    event: {}
  }
}
</script>
<style scoped>
.title {
  color: #000080;
}

</style>