<template>
  <div id="app" class="d-flex flex-column">
    <vue-skip-to to="#main" text="Skip to main content"/>
    <vue-skip-to to="#footer" text="Skip to footer"/>
    <notifications group="main-notifications" />
    <nav>
      <Navbar v-if="!$route.meta.printView"/>
    </nav>
    <main>
      <router-view id="main"/>
    </main>
    <footer>
      <Footer class="mt-auto" v-if="!$route.meta.printView" id="footer"/>
    </footer>
  </div>
</template>

<script>
import Navbar from "@/components/shared/Navbar/Navbar";
import Footer from "@/components/shared/Footer";
import {mapMutations, mapActions} from "vuex";

import {getAuth, onAuthStateChanged} from "firebase/auth";

export default {
  components: {Navbar, Footer},
  mounted() {
    this.$nextTick(() => {
      this.login();
    });

    onAuthStateChanged(getAuth(), (user) => {
      this.getRestaurants();
      this.getFundraisers();
      this.getUserData();
    });
  },
  methods: {
    ...mapMutations("user", ["login"]),
    ...mapActions("user", ['getUserData']),
    ...mapActions('restaurants', ['getRestaurants']),
    ...mapActions('fundraisers', ['getFundraisers']),
  },
};
</script>

<style lang="scss">
@import './assets/custom-vars.scss';

#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  background-color: #fffdfa;
  font-size: 14px;
}

.potomac-btn {
  color: #000080 !important;
  border: #000080 solid 1px;
  border-radius: unset;
  padding: 15px;
  font-size: 16px;
  background-color: #fffdfa !important;
  letter-spacing: 2.5px;
}

.potomac-btn:hover {
  border: #4d66af solid 1px !important;
  background-color: #fffdfa !important;
  color: #4d66af !important;
}

.potomac-btn:focus {
  color: #000080;
  border: #000080 solid 1px;
  border-radius: unset;
  padding: 15px;
  font-size: 16px;
  background-color: #fffdfa !important;
  letter-spacing: 2.5px;
}

.header {
  font-size: 16px;
  color: #000080;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: left;
  padding-top: 30px;
  font-weight: 600;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.admin-border {
  border: 5px #f8bdbd solid;
  padding: 20px;
  max-width: 45rem;
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

</style>

