<template>
  <b-row>
    <b-col cols="12" lg="6" v-if="right" class="d-block d-lg-none">
      <b-img
          class="mb-3 mb-lg-0"
          :src="section.image"
          :alt="section.alt"
      ></b-img>

    </b-col>
    <b-col cols="12" lg="6" v-else>
      <b-img
          class="mb-3 mb-lg-0"
          :src="section.image"
          :alt="section.alt"
      ></b-img>
    </b-col>
    <PresidentTextSection
        :section="section"
        v-if="right"
        class="d-none d-lg-block right"
    />
    <PresidentTextSection
        :section="section"
        v-if="right"
        class="left d-block d-lg-none"
    />
    <PresidentTextSection
        :section="section"
        v-else
        class="left"
    />


    <b-col cols="12" lg="6" v-if="right" class="d-none d-lg-block">
      <b-img :src="section.image" :alt="section.alt"></b-img>
    </b-col>
  </b-row>
</template>
<script>
import PresidentTextSection from "@/views/PresidentTextSection";

export default {
  name: "PresidentSection",
  components: {PresidentTextSection},
  props: {
    section: {},
    right: {
      type: Boolean,
    },
  },
};
</script>
<style scoped>
.left {
  text-align: left;
}

.right {
  text-align: right;
}

img {
  width: 350px;
  box-shadow: 5px 5px 10px 5px rgba(54, 49, 49, 0.13);
}
</style>
