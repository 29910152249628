import {getAuth, onAuthStateChanged} from "firebase/auth";
import {mapState, mapMutations} from 'vuex';

export default {
  data() {
    return {
      rootAdmin: false,
    }
  },

  methods: {
    runLogin() {
      if (this.postLogin) {
        this.postLogin()
      }
    },
    ...mapMutations('user', ['setAdmin']),
    toggleEmulate() {
      if (this.rootAdmin) {
        this.setAdmin(!this.admin)
      }
    }
  },

  computed: {
    ...mapState('user', ['admin'])
  },

  mounted() {
    onAuthStateChanged(getAuth(), (user) => {
      if (!user) {
        this.setAdmin(false);
        return;
      }
      user.getIdTokenResult().then(result => {
        this.setAdmin(result.claims.admin);
        this.rootAdmin = result.claims.admin;
        this.runLogin();
      })
    })
  },

  watch: {
    admin() {
      this.runLogin();
    }
  }
}
